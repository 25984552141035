import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import {
	IPagination,
	TPaginationSettings,
} from '../../lib/interfaces/Pagination';
import { IOrder } from '../../lib/interfaces/Order';
import OrdersManagementTableRow from './OrdersManagementTableRow';

type OrdersManagementTableProps = {
	tableData: IPagination<IOrder>;
	pageChange: (paginationSettings: TPaginationSettings) => void;
	refetch: () => void;
};

const OrdersManagementTable: FC<OrdersManagementTableProps> = ({
	tableData,
	pageChange,
	refetch,
}) => {
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => {
		pageChange({
			page: page + 1,
			limit: tableData.meta.itemsPerPage,
		});
	};

	return (
		<div style={{ marginBottom: '60px' }}>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell align="left">
								<strong>#Order</strong>
							</TableCell>
							<TableCell align="left">
								<strong>Date</strong>
							</TableCell>
							<TableCell>
								<strong>Price</strong>
							</TableCell>
							<TableCell align="left">
								<strong>Status</strong>
							</TableCell>
							<TableCell align="left">
								<strong>Product</strong>
							</TableCell>
							<TableCell align="left">
								<strong>User</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData.items.map((order: IOrder) => (
							<OrdersManagementTableRow key={order.id} row={order} refetch={refetch} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10]}
				component="div"
				count={tableData.meta.totalItems}
				rowsPerPage={tableData.meta.itemsPerPage}
				page={tableData.meta.currentPage - 1}
				onPageChange={handleChangePage}
			/>
		</div>
	);
};

export default OrdersManagementTable;
