import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import uiRootReducer from './reducers/ui/uiRootReducers';
// import { codewayApi } from "../api/services/codeway";
// import { setupListeners } from "@reduxjs/toolkit/query";

const rootReducer = combineReducers({
	ui: uiRootReducer,
	// [codewayApi.reducerPath]: codewayApi.reducer,
});

function setupStore() {
	return configureStore({
		reducer: rootReducer,
	});
}

const store = setupStore();

// setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default store;
