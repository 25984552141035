import { useEffect, useState } from 'react';
import {
	ContentFormPossibleValues,
	FormField,
	IEntityContentField,
	IFormFields,
} from '../../lib/interfaces/Form';
import ContentForm from '../Form/ContentForm';
import {
	convertInitialValues,
	convertValuesBeforeSubmit,
} from '../Form/form.helper';
import {
	CUSTOM_FIELD_MENU_OPTION,
	initialContent,
	supportedLaguages,
} from '../Form/formUtils';
import { swapElements } from '../../lib/helpers/general.helper';
import { availableContentFields } from '../Form/AdditionalFields/availableContentFields';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

type SingleContentEntityProps = {
	isEditMode: boolean;
	initialValues: ContentFormPossibleValues | null;
	saveButtonName: string;
	onSubmit: (formValues: any) => void;
	isLoading: boolean;
	metaFields: FormField[];
	error: Error | null;
	entityName: string;
	contentEntity: CONTENT_ENTITIES;
};

const SingleContentEntity = ({
	isEditMode,
	initialValues,
	onSubmit,
	isLoading,
	error,
	saveButtonName,
	metaFields,
	entityName,
	contentEntity,
}: SingleContentEntityProps) => {
	const [formFields, setFormFields] = useState<IFormFields>();

	useEffect(() => {
		const contentObject: any = {};
		const contentData = initialValues?.content || initialContent;
		supportedLaguages.forEach((lang) => {
			const langContentFields: FormField[] = [];
			contentData[lang].forEach((field: IEntityContentField) => {
				const contentField = {
					...availableContentFields[field.type],
				};
				contentField.name = field.name;
				contentField.label = field.label;
				langContentFields.push(contentField);
				if (field.custom) {
					contentField.custom = true;
				}
				if (field.customType) {
					contentField.customType = field.customType;
				}
			});
			contentObject[lang] = langContentFields;
		});
		const entityFormFields = {
			meta: [...metaFields],
			content: contentObject,
		};
		setFormFields(entityFormFields);
	}, []);

	const handleSubmit = (formValues: any) => {
		if (formFields) {
			const data = convertValuesBeforeSubmit(formValues, formFields);
			onSubmit(data);
		}
	};

	const handleAddField = (field: FormField) => {
		if (formFields) {
			const formFeilds = { ...formFields };
			supportedLaguages.forEach((lang) => {
				const newField = { ...field, name: `${field.name}_${lang}` };
				formFeilds?.content?.[lang].push(newField);
			});
			setFormFields(formFeilds);
		}
	};

	const initialData = initialValues ? convertInitialValues(initialValues) : null;

	const handleCustomFieldAction = (data: any) => {
		const fieldName = data.field.name.split('_')[0];
		if (data.option === CUSTOM_FIELD_MENU_OPTION.DELETE) {
			const updatedContent: any = {};
			supportedLaguages.forEach((lang) => {
				const contentFields = formFields?.content[lang].filter(
					(field) => field.name !== `${fieldName}_${lang}`,
				);
				updatedContent[lang] = contentFields;
			});
			setFormFields({
				meta: formFields ? formFields?.meta : [],
				content: updatedContent,
			});
		}
		if (
			data.option === CUSTOM_FIELD_MENU_OPTION.MOVE_UP ||
			data.option === CUSTOM_FIELD_MENU_OPTION.MOVE_DOWN
		) {
			const up = data.option === CUSTOM_FIELD_MENU_OPTION.MOVE_UP;
			const updatedContent: any = {};
			supportedLaguages.forEach((lang) => {
				const fieldIndex: number =
					formFields?.content[lang].findIndex(
						(field) => field.name === `${fieldName}_${lang}`,
					) || 100;
				const swapFieldIndex = up ? fieldIndex - 1 : fieldIndex + 1;

				const contentFields = swapElements(
					formFields?.content[lang],
					fieldIndex,
					swapFieldIndex,
				);
				updatedContent[lang] = contentFields;
			});
			setFormFields({
				meta: formFields ? formFields?.meta : [],
				content: updatedContent,
			});
		}
		if (
			data.option === CUSTOM_FIELD_MENU_OPTION.ADD_BEFORE ||
			data.option === CUSTOM_FIELD_MENU_OPTION.ADD_AFTER
		) {
			console.log('HERE IS IMPLEMENTATION WITH REDUX');
		}
	};

	return (
		<div style={{ paddingTop: '8px' }}>
			<h1>
				{isEditMode ? 'Edit ' : 'Create '} {entityName}
			</h1>
			{formFields && (
				<ContentForm
					formFields={formFields}
					onSubmit={handleSubmit}
					initialValues={initialData}
					saveButtonName={saveButtonName}
					onAddField={handleAddField}
					handleCustomFieldAction={handleCustomFieldAction}
					contentEntity={contentEntity}
				/>
			)}
			{isLoading && <span>Loading...</span>}
			{error && <div style={{ color: 'red' }}>{error.message}</div>}
		</div>
	);
};

export default SingleContentEntity;
