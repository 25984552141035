import { ISubscriptionPlanDTO } from '../../lib/interfaces/SubscriptionPlan';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getSubscriptionPlanBySlug: (slug: string) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/subscription-plan/${slug}`,
		method: API_METHODS.GET,
	}),

	getAllSubscriptionPlans: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/subscription-plan`,
		method: API_METHODS.GET,
	}),

	createSubscriptionPlan: (data: ISubscriptionPlanDTO) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/subscription-plan`,
		headers: createHeader(null, true),
		data,
	}),

	updateSubscriptionPlan: (
		data: Partial<ISubscriptionPlanDTO>,
		id: number | undefined,
	) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/subscription-plan/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteSubscriptionPlan: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/subscription-plan/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getSubscriptionPlanBySlug = async (slug: string) =>
	axiosRequest(endpoints.getSubscriptionPlanBySlug(slug));

export const getAllSubscriptionPlans = async () =>
	axiosRequest(endpoints.getAllSubscriptionPlans());

export const createSubscriptionPlan = async (data: ISubscriptionPlanDTO) =>
	axiosRequest(endpoints.createSubscriptionPlan(data));

export const updateSubscriptionPlan = async (
	data: Partial<ISubscriptionPlanDTO>,
	id: number | undefined,
) => axiosRequest(endpoints.updateSubscriptionPlan(data, id));

export const deleteSubscriptionPlan = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteSubscriptionPlan(id));
