import { IMetadata } from '../../lib/interfaces/Metadata';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getMetadata: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/metadata-dashboard`,
		method: API_METHODS.GET,
	}),
};

export const getMetadata = (): Promise<IMetadata> =>
	axiosRequest(endpoints.getMetadata());
