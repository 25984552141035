// Libraries
import React, { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Components
import TaskItem from './TaskItem';

// Utils
import { deleteTask, updateTask } from '../../api/apis';
import { Course } from '../../lib/interfaces/Course';
import { Task } from '../../lib/interfaces/Task';

// CSS
import './TasksList.scss';
import { getCourseBySlug, updateCourse } from '../../api/apiRequests/course';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import { useAppDispatch } from '../../hooks/redux';
import { RefType } from '../Form/formUtils';
import RefSelector from '../FormFields/RefSelector/RefSelector';
import { PossibleRefs } from '../../lib/interfaces/RefTypes';

const TasksList = () => {
	const navigate = useNavigate();
	const { slug = '' } = useParams();
	const dispatch = useAppDispatch();

	const [currentTasksOrder, setCurrentTasksOrder] = useState<Task[]>();
	const [openCourseSelector, setOpenCourseSelector] = useState<Boolean>(false);
	const [taskToMove, setTaskToMove] = useState<Task | null>(null);

	const {
		data,
		isError,
		error,
		isLoading,
		refetch: refetchCourse,
	} = useQuery<Course, Error>([`course-${slug}`], () => getCourseBySlug(slug));

	const courseMutation = useMutation<Task, Error, Task>((task) =>
		deleteTask(task.id),
	);

	const changingOrdering = useMutation<Partial<Course>, Error, Partial<Course>>(
		(course) => updateCourse(course, course.id),
	);

	const updateTaskMutation = useMutation<Task, Error, Task>(
		(payload) => updateTask(payload, taskToMove?.id),
		{
			onSuccess() {
				refetchCourse();
				setOpenCourseSelector(false);
			},
		},
	);

	useEffect(() => {
		if (!data?.tasks) return;
		setCurrentTasksOrder(data?.tasks);
		dispatch(
			setRecent({ entity: RecentEntities.COURSE, link: `/course/${slug}/tasks` }),
		);
	}, [data]);

	if (isLoading) {
		return <span>Loading...</span>;
	}

	if (isError) {
		return <span>Error: {error?.message}</span>;
	}

	const openEdit = (task: Task) => {
		navigate(`/edit-task/${task.slug}`);
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) return;

		if (currentTasksOrder?.length) {
			const reorderedTasks = [...currentTasksOrder];
			const [movedTask] = reorderedTasks.splice(result.source.index, 1);
			reorderedTasks.splice(result.destination.index, 0, movedTask);

			setCurrentTasksOrder(reorderedTasks);
			const newTasksOrder: { [key: number]: number } = reorderedTasks.reduce(
				(acc, task, idx) => ({
					...acc,
					[idx + 1]: task.id,
				}),
				{},
			);
			if (data) {
				const courseForUpdate = { tasksOrder: newTasksOrder, id: data.id };
				changingOrdering.mutate(courseForUpdate);
			}
		}
	};

	const removeTask = (task: Task) => {
		// eslint-disable-next-line no-alert
		const confirmation = window.confirm(
			'Are you sure you want to delete this task?',
		);
		if (confirmation) {
			courseMutation.mutate(task);
		}
	};

	const handleOnMove = (task: Task) => {
		console.log(task);
		setTaskToMove(task);
		setOpenCourseSelector(true);
	};

	const handleMoveToCourse = (course: PossibleRefs) => {
		if (taskToMove) {
			updateTaskMutation.mutate({
				courseId: course.id,
			} as Task);
		}
	};

	return (
		<div className="TasksList__container">
			<Button
				variant="contained"
				onClick={() => navigate(`/course/${slug}/create-task`)}
			>
				Create new
			</Button>
			{data?.message && (
				<Alert severity="error" style={{ marginTop: 10 }}>
					{data?.message}
				</Alert>
			)}
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="TasksList">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{currentTasksOrder?.map((task, index) => (
								<Draggable key={task.id} draggableId={task.id.toString()} index={index}>
									{(providedDraggble, snapshot) => (
										<div
											className="TasksList__item"
											ref={providedDraggble.innerRef}
											{...providedDraggble.draggableProps}
											{...providedDraggble.dragHandleProps}
										>
											<TaskItem
												task={task}
												// index={index}
												openEdit={openEdit}
												deleteTask={removeTask}
												onMove={handleOnMove}
												isDragging={snapshot.isDragging}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{openCourseSelector && data && (
				<RefSelector
					refType={RefType.COURSE_REF}
					onSelect={handleMoveToCourse}
					onCancel={() => {
						setOpenCourseSelector(false);
					}}
					disableItemIds={[data?.id]}
				/>
			)}
		</div>
	);
};

export default TasksList;
