import { combineReducers } from 'redux';
import courseReducer from './courseReducer';
import modalsReducer from './modalsReducer';
import recentOpenReducer from './recentOpenReducer';
import userReducer from './userReducer';

const uiRootReducer = combineReducers({
	modals: modalsReducer.reducer,
	user: userReducer.reducer,
	course: courseReducer.reducer,
	recentOpen: recentOpenReducer.reducer,
});

export default uiRootReducer;
