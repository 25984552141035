import React from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ModalProps = {
	isOpen: boolean;
	title?: string;
	content: React.ReactElement;
	handleSave: () => void;
	handleClose: () => void;
	hideActions: boolean;
};

const Modals = (props: ModalProps) => {
	const {
		isOpen,
		handleClose,
		title = '',
		content,
		handleSave,
		hideActions = false,
	} = props;

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{handleClose ? (
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			{!hideActions && (
				<DialogActions>
					<Button onClick={handleClose}>Disagree</Button>
					<Button onClick={handleSave} autoFocus>
						Agree
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};

Modals.defaultProps = {
	title: '',
};

export default Modals;
