import { useQuery } from 'react-query';
import { getMetadata } from '../api/apiRequests/metadata';
import { IMetadata } from '../lib/interfaces/Metadata';

const useGetMetadataQuery = (options = {}) => {
	const metadataCacheKey = 1;
	const metadataLsKey = `metadata_${metadataCacheKey}`;
	const metadataFromLS = localStorage.getItem(metadataLsKey);
	const initialData = metadataFromLS ? JSON.parse(metadataFromLS) : null;
	return useQuery<IMetadata, Error>(['metadata'], () => getMetadata(), {
		onError: (error) => {
			console.error('error: ', error);
		},
		onSuccess(data) {
			localStorage.setItem(metadataLsKey, JSON.stringify(data));
		},
		placeholderData: initialData,
		...options,
	});
};

export default useGetMetadataQuery;
