import cloneDeep from 'lodash/cloneDeep';
import { FormField } from '../../lib/interfaces/Form';
import { ISubscriptionPlan } from '../../lib/interfaces/SubscriptionPlan';
import { Task } from '../../lib/interfaces/Task';
import { FIELD_TYPES } from '../Form/formUtils';
import { generateOptions } from '../FormFields/Autocomplete/autocompleteUtils';

export const taskFields: FormField[] = [
	{
		name: 'isPublished',
		init: true,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'customClass',
		init: '',
		label: 'Custom CSS class',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: true,
		defaultOptions: [
			{
				label: 'cwt-menu-child',
				id: 'cwt-menu-child',
			},
		],
	},
	{
		name: 'subscriptionPlansIds',
		init: [],
		label: 'Available for plans',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: false,
	},
];

export const getTaskFormFields = (
	subscriptionPlans: ISubscriptionPlan[],
	data?: Task,
) => {
	const fields = cloneDeep(taskFields);
	fields.forEach((field: FormField) => {
		if (field.name === 'subscriptionPlansIds') {
			field = generateOptions({
				selectedIds: data?.subscriptionPlansIds,
				optionsList: subscriptionPlans,
				labelKey: 'name',
				idKey: 'id',
				field,
			});
		}
		if (field.name === 'customClass' && data) {
			field = generateOptions({
				selectedIds: data.customClass,
				optionsList: field.defaultOptions,
				field,
			});
		}
	});
	return fields;
};
