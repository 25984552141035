import { Divider, Button } from '@mui/material';
import { FC } from 'react';
import { IPagination } from '../../../lib/interfaces/Pagination';
import { PossibleRefs } from '../../../lib/interfaces/RefTypes';

type RefSelectorTableProps = {
	onSelect: (item: PossibleRefs) => void;
	data: IPagination<PossibleRefs>;
	disableItemIds?: number[];
};

const RefSelectorTable: FC<RefSelectorTableProps> = ({
	onSelect,
	data,
	disableItemIds,
}) => (
	<div>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '5px',
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						paddingRight: '5px',
						width: '30px',
					}}
				>
					<strong>ID</strong>
				</div>
				<div>
					<strong>Name</strong>
				</div>
			</div>
		</div>
		<Divider />
		{data.items.map((item) => (
			<div
				key={item.id}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '5px',
					borderBottom: '1px solid #ccc',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							paddingRight: '5px',
							width: '30px',
						}}
					>
						{item.id}
					</div>
					<div>{item.name}</div>
				</div>
				<Button
					variant="outlined"
					sx={{ marginLeft: '5px' }}
					onClick={() => onSelect(item)}
					disabled={disableItemIds ? disableItemIds.includes(item.id) : false}
				>
					{disableItemIds && disableItemIds.includes(item.id) ? 'Added' : 'Select'}
				</Button>
			</div>
		))}
	</div>
);

export default RefSelectorTable;
