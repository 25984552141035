import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LoginModal from '../Auth/LoginModal';

const Modals = () => {
	const isLoginModalOpen = useSelector(
		(state: RootState) => state.ui.modals.isLoginModalOpen,
	);

	return isLoginModalOpen ? <LoginModal isOpen={isLoginModalOpen} /> : null;
};

export default Modals;
