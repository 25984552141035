import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Course } from '../../lib/interfaces/Course';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';
import {
	deleteCourse,
	getPaginatedCourses,
} from '../../api/apiRequests/course';
import './Courses.scss';

const Courses = () => {
	const navigate = useNavigate();
	const openEditTasks = (course: Course) => {
		navigate(`/course/${course.slug}/tasks`);
	};
	const renderListItem = (course: Course) => (
		<span>
			{course.name}
			<Button onClick={() => openEditTasks(course)}>
				Edit Tasks ({course.totalTasks})
			</Button>
		</span>
	);
	const getId = (course: Course) => course.id;
	const getSlug = (course: Course) => course.slug;

	return (
		<EntityItemsPaginatedList<Course>
			entityName="Course"
			getPaginatedEntityFn={getPaginatedCourses}
			deleteEntityFn={deleteCourse}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-course/"
			navigateCreatePath="/create-course/"
			defaultPaginationSettings={{
				page: 1,
				limit: 20,
			}}
		/>
	);
};

export default Courses;
