import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getProductBySlug, updateProduct } from '../../api/apis';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { IProduct } from '../../lib/interfaces/Product';
import { checkAndAddInitialFields } from '../Form/form.helper';
import { initialContentForProduct } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import useGetMetadataQuery from '../../hooks/useGetMetadata';
import { getProductFields } from './productFields';

const ProductEdit = () => {
	const { slug = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const { data: metadata } = useGetMetadataQuery();

	const singleProduct = useQuery<IProduct, Error>([`product-${slug}`], () =>
		getProductBySlug(slug),
	);
	const { data, isLoading, isSuccess, error, refetch } = singleProduct;

	const updateProductMutation = useMutation<IProduct, Error, IProduct>(
		(formValues: IProduct) => updateProduct(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateProductMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Code Task has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateProductMutation.mutate(formValues);
		}
	};

	const modifiedData = data
		? checkAndAddInitialFields(data, initialContentForProduct)
		: null;

	return isSuccess && metadata ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={modifiedData}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={getProductFields(metadata, data)}
			entityName="Product"
			contentEntity={CONTENT_ENTITIES.PRODUCT}
		/>
	) : null;
};

export default ProductEdit;
