import { FC } from 'react';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { IOrder } from '../../lib/interfaces/Order';

type OrderStatusChangeLogProps = {
	order: IOrder;
};

const OrderStatusChangeLog: FC<OrderStatusChangeLogProps> = ({ order }) => {
	const rows = order.statusChangeLog.changes;
	return (
		<div>
			<Typography variant="h6" gutterBottom component="div">
				<strong>Status change log</strong>
			</Typography>
			<TableContainer component={Box}>
				<Table sx={{ minWidth: 350, maxWidth: 700 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="left">From</TableCell>
							<TableCell align="left">To</TableCell>
							<TableCell align="left">Date</TableCell>
							<TableCell align="left">By</TableCell>
							<TableCell align="left">Notes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.date}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{row.from}</TableCell>
								<TableCell align="left">{row.to}</TableCell>
								<TableCell align="left">{row.date}</TableCell>
								<TableCell align="left">
									{row.byName} {row.byId ? `(id: ${row.byId})` : ''}
								</TableCell>
								<TableCell align="left">{row.note}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default OrderStatusChangeLog;
