import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ProductStatus } from '../../lib/helpers/consts';
import { OrderFilter } from '../../lib/interfaces/Order';
import { getAllProducts } from '../../api/apis';
import { IProduct } from '../../lib/interfaces/Product';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

type OrdersManagementFilterProps = {
	filterChange: (filter: OrderFilter) => void;
	currentFilter: OrderFilter;
};

const OrdersManagementFilter: FC<OrdersManagementFilterProps> = ({
	filterChange,
	currentFilter,
}) => {
	const [status, setStatus] = useState<ProductStatus[]>([]);
	const [filterProducts, setFilterProducts] = useState<number[]>([]);

	const { data: productsData } = useQuery<IProduct[], Error>(['products'], () =>
		getAllProducts(),
	);

	const handleStatusChange = (event: SelectChangeEvent<ProductStatus[]>) => {
		const {
			target: { value },
		} = event;
		if (value instanceof Array) {
			setStatus(value);
		}
	};

	const handleProductChange = (event: SelectChangeEvent<number[]>) => {
		const {
			target: { value },
		} = event;
		if (value instanceof Array) {
			setFilterProducts(value);
		}
	};

	const applyFilter = () => {
		filterChange({
			...currentFilter,
			products: filterProducts,
			status,
		});
	};

	// useEffect(() => {
	// 	if (currentFilter.status) {
	// 		setStatus(currentFilter.status);
	// 	}
	// }, [currentFilter]);

	return (
		<div>
			<FormControl sx={{ m: 1, width: 300 }}>
				<InputLabel id="filter-status">Status</InputLabel>
				<Select
					labelId="filter-status"
					multiple
					value={status}
					onChange={handleStatusChange}
					input={<OutlinedInput label="Status" />}
					MenuProps={MenuProps}
				>
					{Object.values(ProductStatus).map((value) => (
						<MenuItem value={value} key={value}>
							{value}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{productsData && (
				<FormControl sx={{ m: 1, width: 300 }}>
					<InputLabel id="product-status">Product</InputLabel>
					<Select
						labelId="product-status"
						multiple
						value={filterProducts}
						onChange={handleProductChange}
						input={<OutlinedInput label="Product" />}
						MenuProps={MenuProps}
					>
						{productsData.map((product: IProduct) => (
							<MenuItem value={product.id} key={product.id}>
								{product.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			<Button variant="contained" onClick={applyFilter}>
				Filter
			</Button>
		</div>
	);
};

export default OrdersManagementFilter;
