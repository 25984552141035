import { FormField } from '../../lib/interfaces/Form';
import { FIELD_TYPES, INPUT_TYPES } from '../Form/formUtils';

export const subscriptionPlanFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'defaultPrice',
		init: 0,
		label: 'Default price',
		required: true,
		type: FIELD_TYPES.TEXT,
		inputType: INPUT_TYPES.NUMBER,
	},
	{
		name: 'globalDiscount',
		init: 0,
		label: 'Global discount',
		required: true,
		type: FIELD_TYPES.TEXT,
		inputType: INPUT_TYPES.NUMBER,
	},
	{
		name: 'isPublished',
		init: false,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	// {
	// 	name: 'isDefault',
	// 	init: false,
	// 	label: 'Default plan',
	// 	required: false,
	// 	type: FIELD_TYPES.CHECKBOX,
	// },
	{
		name: 'imageUrl',
		init: '',
		label: 'Image URL',
		required: true,
		type: FIELD_TYPES.FILE,
	},
	{
		name: 'sortNumber',
		init: [],
		label: 'Plan sort order',
		required: true,
		freeSolo: false,
		type: FIELD_TYPES.TEXT,
		inputType: INPUT_TYPES.NUMBER,
	},
];
