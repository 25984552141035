import { IVideoItem } from '../../lib/interfaces/VideoItem';
import {
	deleteVideoItem,
	getPaginatedVideoItems,
} from '../../api/apiRequests/videoItems';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';

const VideoLibrary = () => {
	const renderListItem = (videoItem: IVideoItem) => (
		<span>{videoItem.name}</span>
	);
	const getId = (videoItem: IVideoItem) => videoItem.id;
	const getSlug = (videoItem: IVideoItem) => videoItem.id.toString();

	return (
		<EntityItemsPaginatedList<IVideoItem>
			entityName="Video"
			getPaginatedEntityFn={getPaginatedVideoItems}
			deleteEntityFn={deleteVideoItem}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-video-item/"
			navigateCreatePath="/create-video-item/"
		/>
	);
};

export default VideoLibrary;
