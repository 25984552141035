import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import {
	codeTaskFields,
	initialContent,
	codeTaskInitialContentFields,
	supportedLaguages,
} from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { ICodeTask } from '../../lib/interfaces/CodeTask';
import { createCodeTask } from '../../api/apiRequests/codeTask';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { IEntityContentField } from '../../lib/interfaces/Form';

const CodeTaskCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createCodeTaskMutation = useMutation<ICodeTask, Error, ICodeTask>(
		(formValues) => createCodeTask(formValues),
	);
	const { isLoading, isSuccess, error } = createCodeTaskMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['codeTasks']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createCodeTaskMutation.mutate(formValues);
	};

	const initialCodeTaskContent = cloneDeep(initialContent);
	supportedLaguages.forEach((lang) => {
		codeTaskInitialContentFields.forEach((field: IEntityContentField) => {
			initialCodeTaskContent[lang].push({
				...field,
				name: `${field.name}_${lang}`,
			});
		});
	});

	const codeTaskInit: Partial<ICodeTask> = {
		content: initialCodeTaskContent,
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={codeTaskInit}
			error={error}
			saveButtonName="Create"
			metaFields={codeTaskFields}
			entityName="Code Task"
			contentEntity={CONTENT_ENTITIES.CODE_TASK}
		/>
	);
};

export default CodeTaskCreate;
