import {
	deleteCourseOccupation,
	getPaginatedCourseOccupations,
} from '../../api/apiRequests/courseOccupation';
import { ICourseOccupation } from '../../lib/interfaces/CourseOccupation';

import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';

const CourseOccupations = () => {
	const renderListItem = (courseOccupation: ICourseOccupation) => (
		<span>
			{courseOccupation.name} ({courseOccupation.totalCourses})
		</span>
	);
	const getId = (courseOccupation: ICourseOccupation) => courseOccupation.id;
	const getSlug = (courseOccupation: ICourseOccupation) =>
		courseOccupation.id.toString();

	return (
		<EntityItemsPaginatedList<ICourseOccupation>
			entityName="Course Occupation"
			getPaginatedEntityFn={getPaginatedCourseOccupations}
			deleteEntityFn={deleteCourseOccupation}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-course-occupation/"
			navigateCreatePath="/create-course-occupation/"
		/>
	);
};

export default CourseOccupations;
