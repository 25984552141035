import React, { FC, useState } from 'react';
import { TextField, Button } from '@mui/material';
import { RefsFilter } from '../../lib/interfaces/RefTypes';

type EntityFilterProps = {
	onFilterChange: (filter: RefsFilter | undefined) => void;
	currentFilter: RefsFilter | undefined;
};

const EntityFilter: FC<EntityFilterProps> = ({
	onFilterChange,
	currentFilter,
}) => {
	const [filterName, setFilterName] = useState<string>(
		currentFilter?.name || '',
	);

	const handleNameSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFilterName(e.target.value);

	const handleFilterChangeRequest = () => {
		onFilterChange({
			name: filterName,
		});
	};

	const handleClearFilter = () => {
		setFilterName('');
		onFilterChange(undefined);
	};

	return (
		<div
			style={{
				display: 'flex',
				paddingTop: '15px',
				paddingBottom: '10px',
				maxWidth: '500px',
				paddingLeft: '20px',
			}}
		>
			<TextField
				style={{ marginRight: '10px' }}
				onChange={handleNameSearchChange}
				variant="outlined"
				fullWidth
				label="Name"
				value={filterName}
			/>
			<Button
				variant="outlined"
				onClick={handleFilterChangeRequest}
				sx={{ marginRight: '5px' }}
			>
				Filter
			</Button>
			{filterName && (
				<Button variant="contained" onClick={handleClearFilter}>
					Clear
				</Button>
			)}
		</div>
	);
};

export default EntityFilter;
