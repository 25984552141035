const ru = {
	signIn: 'Войти',
	home: 'Курсы',
	english: 'English',
	russian: 'Русский',
	hey: 'Привет',
	logout: 'Выйти',
	login: 'Войти',
	name: 'Имя',
	email: 'Ваш Email',
	password: 'Пароль',
	register: 'Регистрация',
	goToCourse: 'К Заданиям',
	takeThisCourse: 'Взять курс',
	coursesInProgress: 'Курсы в процессе',
	availableCourses: 'Доступные Курсы',
	completedCourses: 'Пройденные курсы',
	noProgressCourses: 'Вы еще не взяли ни одного курса',
	noAvailableCourses: 'Поздравляем! Вы прошли все курсы!',
	noCompletedCourses: 'Вы пока не прошли ни одного курса',
	completeTask: 'Отметить Задание как выполненную',
	setTaskIncomplete: 'Сделать Задачу Невыполненной',
	replyTo: 'Ответить',
	editComment: 'Редактировать комментарий',
	writeComment: 'Добавить комментарий',
	linkLabel:
		'Ссылка (только с: github.com, codepen.io, jsfiddle.net, codesandbox.io)',
	yourMessage: 'Ваше сообщение',
	whitelistLinksSupport: 'Мы поддерживаем ссылки только с',
	linkIsNotValid: 'Неправильный линк',
	reply: 'Ответить',
	send: 'Послать',
	edit: 'Отредактировать',
	cancel: 'Отмена',
	congrats: 'Поздравляем!',
	completeCourseMessage: 'Вы закончили этот курс!',
	takeAnotherCourse: 'Взять другой курс',
	hint: 'Подсказка',
	articles: 'Статьи',
	practice: 'Практика',
	theory: 'Теория',
	all: 'Все',
	yourProgress: 'Ваш прогресс',
	donate: 'Donate',
};

export default ru;
