import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// import BottomNavigation from '@mui/material/BottomNavigation';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ClassIcon from '@mui/icons-material/Class';
import QuizIcon from '@mui/icons-material/Quiz';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Button, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
	RecentEntities,
	clearAll,
} from '../../redux/reducers/ui/recentOpenReducer';
import { useAppDispatch } from '../../hooks/redux';

const SimpleBottomNavigation = () => {
	const recentLinks = useSelector((state: RootState) => state.ui.recentOpen);
	const navigate = useNavigate();
	const displatch = useAppDispatch();

	const handleNavigate = (entity: RecentEntities) => {
		if (recentLinks[entity]) {
			navigate(recentLinks[entity] as string);
		}
	};

	const clearAllRecents = () => {
		displatch(clearAll());
	};

	const buttons: { text: string; entity: RecentEntities; icon: any }[] = [
		{
			text: 'Course',
			entity: RecentEntities.COURSE,
			icon: <FeaturedPlayListIcon />,
		},
		{
			text: 'Task',
			entity: RecentEntities.TASK,
			icon: <ClassIcon />,
		},
		{
			text: 'Code Task',
			entity: RecentEntities.CODE_TASK,
			icon: <IntegrationInstructionsIcon />,
		},
		{
			text: 'Quiz',
			entity: RecentEntities.QUIZ,
			icon: <QuizIcon />,
		},
		{
			text: 'Video',
			entity: RecentEntities.VIDEO,
			icon: <VideocamIcon />,
		},
	];

	return (
		<div
			style={{
				width: '50px',
				height: 'calc(100vh - 60px)',
				position: 'relative',
			}}
		>
			<div
				style={{
					width: '50px',
					height: 'calc(100vh - 60px)',
					position: 'fixed',
					top: 60,
					right: 10,
					zIndex: 1,
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ fontSize: '12px', textAlign: 'center', paddingTop: '10px' }}>
						Recently open:{' '}
					</div>
					<div style={{}}>
						{/* <div style={{ height: '90px', lineHeight: '54px' }}>Last opened: </div> */}
						{buttons.map((button) => (
							<div key={button.entity} style={{ paddingTop: '10px' }}>
								<Button
									sx={{ minWidth: '100%' }}
									onClick={() => handleNavigate(button.entity)}
									disabled={!recentLinks[button.entity]}
								>
									{button.icon}
								</Button>
								<div style={{ fontSize: '12px', textAlign: 'center' }}>
									{button.text}
								</div>
							</div>
						))}
					</div>
					<Divider sx={{ margin: '20px 0 10px 0' }} />
					<Button sx={{ minWidth: '100%' }} onClick={() => clearAllRecents()}>
						Clear
					</Button>
				</div>
			</div>
		</div>
	);
};
export default SimpleBottomNavigation;
