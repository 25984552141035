import { createSlice } from '@reduxjs/toolkit';

const modalsReducer = createSlice({
	name: 'modals',
	initialState: {
		isLoginModalOpen: false,
		isRegisterModalOpen: false,
	},
	reducers: {
		setLoginModalOpen: (state, action) => {
			state.isLoginModalOpen = action.payload;
		},
		setRegisterModalOpen: (state, action) => {
			state.isRegisterModalOpen = action.payload;
		},
	},
});

export const { setLoginModalOpen, setRegisterModalOpen } =
	modalsReducer.actions;
export default modalsReducer;
