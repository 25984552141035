import React, { FC, useState } from 'react';
import { Button, Checkbox } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import CKEditorField from '../CKEditor';
import { QuizOption, QuizQuestionTypes } from '../../../lib/interfaces/Quiz';

type QuizQuestionOptionsProps = {
	question: any;
	updateOptions: ({
		options,
		answer,
	}: {
		options: QuizOption[];
		answer: string[];
	}) => void;
};

const QuizQuestionOptions: FC<QuizQuestionOptionsProps> = ({
	question,
	updateOptions,
}) => {
	const [questionOptions, setQuestionOptions] = useState<QuizOption[]>(
		question?.options || [],
	);
	const [questionAnswer, setQuestionAnswer] = useState<string[]>(
		question?.answer || [],
	);

	const handleChange = ({
		answer,
		options,
	}: {
		answer?: string[];
		options?: QuizOption[];
	}) => {
		updateOptions({
			answer: answer || [],
			options: options || [],
		});
	};

	/* const handleTypeChange = (event: SelectChangeEvent) => {
		setQuestionType(Number(event.target.value));
		handleChange({ type: Number(event.target.value) });
	};

	const handleOptionsChange = (data: string) => {
		setQuestionText(data);
		handleChange({ text: data });
	}; */

	const handleAnswerChange = (isChecked: boolean, id: string) => {
		let updatedAnswer = [...questionAnswer];
		if (question.type === QuizQuestionTypes.OPTIONS) {
			if (isChecked) {
				updatedAnswer[0] = id;
			} else {
				updatedAnswer = [];
			}
		}
		if (question.type === QuizQuestionTypes.MULTIPLE_OPTIONS) {
			const index = updatedAnswer.findIndex((el) => el === id);
			if (index > -1) {
				updatedAnswer.splice(index, 1);
			} else {
				updatedAnswer.push(id);
			}
		}
		setQuestionAnswer(updatedAnswer);
		handleChange({ answer: updatedAnswer, options: questionOptions });
	};

	const handleOptionTextChange = (data: string, id: string) => {
		const updatedOptions = [...questionOptions];
		const index = questionOptions.findIndex(
			(option: QuizOption) => option.id === id,
		);
		updatedOptions[index].text = data;
		setQuestionOptions(updatedOptions);
		handleChange({ options: updatedOptions, answer: questionAnswer });
	};

	const addOption = () => {
		const newOption: QuizOption = {
			id: uuidv4(),
			text: '',
		};
		const updatedOptions = [...questionOptions, newOption];
		setQuestionOptions([...questionOptions, newOption]);
		handleChange({ options: updatedOptions, answer: questionAnswer });
	};

	const removeOption = (id: string) => {
		const updatedOptions = [...questionOptions];
		const index = questionOptions.findIndex(
			(option: QuizOption) => option.id === id,
		);
		updatedOptions.splice(index, 1);
		setQuestionOptions(updatedOptions);
		handleChange({ options: updatedOptions, answer: questionAnswer });
	};

	return (
		<div>
			<div style={{ marginTop: '15px' }}>
				{questionOptions.map((option: any) => (
					<div style={{ display: 'flex' }} key={option.id}>
						<Checkbox
							checked={questionAnswer.includes(option.id)}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleAnswerChange(e.target.checked, option.id)
							}
						/>
						<CKEditorField
							label="Option Text"
							defaultValue={option.text}
							onChange={(data: string) => {
								handleOptionTextChange(data, option.id);
							}}
						/>
						<Button onClick={() => removeOption(option.id)}>Remove</Button>
					</div>
				))}
				<Button onClick={addOption}>Add Option</Button>
			</div>
		</div>
	);
};

export default QuizQuestionOptions;
