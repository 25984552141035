import { FormField } from '../../../lib/interfaces/Form';
import { OptionEntity } from '../Tags';

type generateOptionsProps = {
	selectedIds?: any[];
	optionsList?: any[];
	labelKey?: string;
	idKey?: string;
	field: FormField;
};

type generateOptionsOutput = {
	defaultOptions: OptionEntity[];
	selectedOptions: OptionEntity[];
};

export const generateOptions = ({
	selectedIds,
	optionsList,
	labelKey,
	idKey,
	field,
}: generateOptionsProps): FormField => {
	const result: generateOptionsOutput = {
		defaultOptions: [],
		selectedOptions: [],
	};
	// create defaultOptions if we pass dynamic options from API metadata
	// with mapped label and id keys
	if (labelKey && idKey) {
		result.defaultOptions =
			optionsList?.map((option) => ({
				label: option[labelKey as string],
				id: option[idKey as string],
			})) || [];
	}
	// create selected options according to what is coming from API
	if (selectedIds) {
		result.selectedOptions = result.defaultOptions.filter((option) =>
			selectedIds?.includes(option.id),
		);
	}
	// create selected options if we have array of strings
	if (selectedIds && (!labelKey || !idKey)) {
		result.selectedOptions = selectedIds.map((option) => ({
			label: option,
			id: option,
		}));
	}
	// assign defaultOptions & selectedOptions to field
	field.defaultOptions = result.defaultOptions.length
		? result.defaultOptions
		: field.defaultOptions || [];
	field.selectedOptions = result.selectedOptions;

	// return modified field
	return field;
};
