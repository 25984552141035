import { Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getOrders } from '../../api/apiRequests/orders';
import { ProductStatus } from '../../lib/helpers/consts';
import { IOrder, OrderFilter } from '../../lib/interfaces/Order';
import {
	IPagination,
	TPaginationSettings,
} from '../../lib/interfaces/Pagination';
import OrdersManagementFilter from './OrdersManagementFilter';
import OrdersManagementTable from './OrdersManagementTable';

const OrdersManagement: FC = () => {
	const [orderFilter, setOrderFilter] = useState<OrderFilter>({
		status: [ProductStatus.PAID],
	});
	const [pagination, setPagination] = useState<TPaginationSettings | null>(null);

	const statusKey = orderFilter?.status?.join('-') || '';
	const productsKey = orderFilter?.products?.join('-') || '';
	const paginationKey = pagination
		? `${pagination.page}-${pagination.limit}`
		: '';
	const { data, refetch } = useQuery<IPagination<IOrder>, Error>(
		['orders', statusKey, productsKey, paginationKey],
		() => getOrders(orderFilter, pagination),
	);

	const filterChange = (filter: OrderFilter) => {
		setPagination(null);
		setOrderFilter(filter);
	};

	const onPageChange = (paginationSettings: TPaginationSettings) => {
		setPagination(paginationSettings);
	};

	return (
		<div>
			<Typography
				component="h1"
				variant="h5"
				style={{ padding: '20px 20px 5px 20px' }}
			>
				Orders Management
			</Typography>
			<div>
				<OrdersManagementFilter
					filterChange={filterChange}
					currentFilter={orderFilter}
				/>
			</div>
			{data && (
				<OrdersManagementTable
					tableData={data}
					pageChange={onPageChange}
					refetch={refetch}
				/>
			)}
		</div>
	);
};

export default OrdersManagement;
