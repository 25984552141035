import { createTheme } from '@mui/material';

const theme = createTheme({
	typography: {
		button: {
			textTransform: 'none',
		},
	},
});

export default theme;
