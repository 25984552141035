// Libraries
import React, { useState, useEffect, FC, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
	Avatar,
	Button,
	Container,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

// Components
import SimpleModal from '../Modals/SimpleModal';

// Utils
import { setCurrentUser } from '../../redux/reducers/ui/userReducer';
import { setLoginModalOpen } from '../../redux/reducers/ui/modalsReducer';
import { login } from '../../api/apis';
import { UserDTO, UserRO } from '../../lib/interfaces/User';
import { useAppDispatch } from '../../hooks/redux';

// CSS
import './LoginModal.scss';

type LoginModalProps = {
	isOpen: boolean;
};

const LoginModal: FC<LoginModalProps> = (props) => {
	const { isOpen } = props;
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [loginError, setLoginError] = useState<Error | null>(null);
	const [formValues, setFormValues] = useState<UserDTO>({
		email: '',
		password: '',
	});
	const loginMutation = useMutation<UserRO, Error, UserDTO>((data) =>
		login(data),
	);
	const { data, error, isLoading } = loginMutation;

	const handleClose = useCallback(() => {
		dispatch(setLoginModalOpen(false));
	}, [dispatch]);

	useEffect(() => {
		if (data) {
			localStorage.setItem('user', JSON.stringify(data));
			dispatch(setCurrentUser(data.user));
			handleClose();
		}
		if (error) {
			setLoginError(error);
		}
	}, [data, error, isLoading, dispatch, handleClose]);

	const handleSubmit = () => {
		loginMutation.mutate(formValues);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const content: React.ReactElement = (
		<Container component="main" maxWidth="xs">
			<div className="LoginModal__wrapper">
				<div className="LoginModal__title">
					<Avatar className="LoginModal__avatar">
						<LockOutlined />
					</Avatar>
					<Typography component="h1" variant="h5">
						{t('login')}
					</Typography>
				</div>
				<form noValidate onSubmit={(e) => e.preventDefault()}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
								id="email"
								label={t('email')}
								name="email"
								autoComplete="email"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
								name="password"
								label={t('password')}
								type="password"
								id="password"
								autoComplete="current-password"
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								onClick={handleSubmit}
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className="LoginModal__submit-btn"
							>
								Log In
							</Button>
							<div style={{ color: 'red' }}>
								<Typography variant="body1">{loginError?.message}</Typography>
							</div>
							<div className="LoginModal__extra-options">
								{/* <a href="#" onClick={(e) => register(e)}>
									Don't have an account? Sign Up
								</a> */}
								<Link to="/forgot-password">Forgot Password?</Link>
							</div>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);

	return (
		<SimpleModal
			isOpen={isOpen}
			handleClose={() => {}}
			handleSave={handleSubmit}
			content={content}
			hideActions
		/>
	);
};

LoginModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};

export default LoginModal;
