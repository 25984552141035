import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
// import cloneDeep from 'lodash/cloneDeep';
// import { initialContent } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { IVideoItem } from '../../lib/interfaces/VideoItem';
import { createVideoItem } from '../../api/apiRequests/videoItems';
import {
	videoItemFields,
	createVideoInitialContent,
} from './VideoItemsFormFields';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

const VideoItemCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createVideoItemMutation = useMutation<IVideoItem, Error, IVideoItem>(
		(formValues) => createVideoItem(formValues),
	);
	const { isLoading, isSuccess, error } = createVideoItemMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['videoItems']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createVideoItemMutation.mutate(formValues);
	};

	const initialVideoItemContent = createVideoInitialContent();
	const videoItemInit: Partial<IVideoItem> = {
		content: initialVideoItemContent,
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={videoItemInit}
			error={error}
			saveButtonName="Create"
			metaFields={videoItemFields}
			entityName="Video"
			contentEntity={CONTENT_ENTITIES.VIDEO_ITEM}
		/>
	);
};

export default VideoItemCreate;
