import React, { FC, useEffect, useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

type DependencyValueType = {
	package: string;
	version: string;
};

type DependencyType = {
	[key: string]: string;
};

type SandPackDependenciesProps = {
	onDependencyChange: (deps: DependencyType) => void;
	dependencies?: DependencyType;
};

const SandPackDependencies: FC<SandPackDependenciesProps> = ({
	onDependencyChange,
	dependencies,
}) => {
	console.log('dependencies: ', dependencies);
	const initialDeps = dependencies || { '': '' };
	const [deps, setDeps] = useState<DependencyType>({ ...initialDeps });
	const initialDependencyState: DependencyValueType = {
		package: '',
		version: '',
	};
	const [value, setValue] = useState<DependencyValueType>({
		...initialDependencyState,
	});

	const handleUpdate = (newDepValue: DependencyType) => {
		delete newDepValue[''];
		console.log('newDepValue: ', newDepValue);
		onDependencyChange(newDepValue);
	};

	const onSave = () => {
		const newDepValue = {
			...deps,
			[value.package]: value.version,
			'': '',
		};
		setDeps(newDepValue);
		handleUpdate({ ...newDepValue });
	};

	const removeDep = (dep: string) => {
		const depsNewValue = { ...deps };
		delete depsNewValue[dep];
		setDeps(depsNewValue);
		handleUpdate({ ...depsNewValue });
	};

	useEffect(() => {
		setValue({ ...initialDependencyState });
	}, [deps]);

	return (
		<div style={{ padding: '15px 0' }}>
			<Typography variant="body1">NPM dependecies:</Typography>
			{Object.entries(deps).map(([dep, version]) => (
				<div key={dep}>
					{dep && version ? (
						<>
							<span>{dep}</span> : <span>{version}</span>
							<IconButton
								aria-label="delete"
								size="medium"
								onClick={() => removeDep(dep)}
							>
								<DeleteForeverIcon fontSize="inherit" />
							</IconButton>
						</>
					) : null}
				</div>
			))}

			<div>
				<TextField
					value={value.package}
					size="small"
					placeholder="Package"
					onChange={(e) => setValue({ ...value, package: e.target.value })}
				/>
				<TextField
					value={value.version}
					size="small"
					placeholder="Version"
					onChange={(e) => setValue({ ...value, version: e.target.value })}
				/>
				<Button variant="text" onClick={onSave}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default SandPackDependencies;
