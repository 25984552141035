import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createLegal } from '../../api/apiRequests/legal';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { ILegalDTO } from '../../lib/interfaces/Legal';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { legalFields } from './legalFormFields';

const LegalCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createLegalMutation = useMutation<ILegalDTO, Error, ILegalDTO>(
		(formValues) => createLegal(formValues),
	);
	const { isLoading, isSuccess, error } = createLegalMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['legals']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => createLegalMutation.mutate(formValues);

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={null}
			error={error}
			saveButtonName="Create"
			metaFields={legalFields}
			entityName="Legal"
			contentEntity={CONTENT_ENTITIES.LEGAL}
		/>
	);
};

export default LegalCreate;
