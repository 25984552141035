import React, { FC, useState } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { FormField, ISelectFieldOption } from '../../lib/interfaces/Form';

type SelectFieldProps = {
	field: FormField;
	options?: ISelectFieldOption[];
	formValues: any;
	validation?: any;
	handleChange: (value: any, fieldName: string) => void;
};

const SelectField: FC<SelectFieldProps> = ({
	field,
	formValues,
	validation,
	options,
	handleChange,
}) => {
	const [selectedValue, setSelectedValue] = useState(
		formValues[field.name]?.value || '',
	);

	const handleTypeChange = (event: SelectChangeEvent) => {
		setSelectedValue(event.target.value);
		handleChange(event.target.value, field.name);
	};

	const selectOptions = options || field.options;

	return (
		<FormControl fullWidth>
			<InputLabel id="demo-simple-select-label">{field.label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedValue}
				label={field.label}
				onChange={handleTypeChange}
				style={{ color: validation?.[field.name] ? 'red' : 'inherit' }}
			>
				{!!selectOptions &&
					selectOptions.map((option: any) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

export default SelectField;
