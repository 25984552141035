import { deleteQuiz, getPaginatedQuizes } from '../../api/apiRequests/quiz';
import { IQuiz } from '../../lib/interfaces/Quiz';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';

const Quizzes = () => {
	const renderListItem = (quiz: IQuiz) => <span>{quiz.name}</span>;
	const getId = (quiz: IQuiz) => quiz.id;
	const getSlug = (quiz: IQuiz) => quiz.slug;

	return (
		<EntityItemsPaginatedList<IQuiz>
			entityName="Quiz"
			getPaginatedEntityFn={getPaginatedQuizes}
			deleteEntityFn={deleteQuiz}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-quiz/"
			navigateCreatePath="/create-quiz/"
		/>
	);
};

export default Quizzes;
