/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Libraries
import React, { FC } from 'react';
import { Button } from '@mui/material';

// Utils
import { Task } from '../../lib/interfaces/Task';

// CSS
import './TaskItem.scss';

type TaskItemProps = {
	task: Task;
	// index: number;
	isDragging: boolean;
	openEdit: (task: Task) => void;
	deleteTask: (task: Task) => void;
	onMove: (task: Task) => void;
};

const TaskItem: FC<TaskItemProps> = ({
	task,
	isDragging,
	openEdit,
	deleteTask,
	onMove,
}) => (
	<div className={`TaskItem__container ${isDragging ? 'dragging' : ''}`}>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<h3 className="TaskItem__title">
				<span onClick={() => openEdit(task)}>{task.name}</span>
			</h3>
			<div className="TaskItem__actions">
				<Button
					sx={{ marginRight: '10px' }}
					size="small"
					onClick={() => onMove(task)}
				>
					Move
				</Button>
				<Button
					sx={{ marginRight: '10px' }}
					size="small"
					onClick={() => openEdit(task)}
				>
					Edit
				</Button>
				<Button size="small" onClick={() => deleteTask(task)}>
					Delete
				</Button>
			</div>
		</div>
	</div>
);

export default TaskItem;
