import React, { useEffect, useState } from 'react';
import { Button, TablePagination, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	IPagination,
	TPaginationSettings,
} from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import EntityFilter from './EntityFilter';

type EntityItemsPaginatedListProps<T> = {
	entityName: string;
	getPaginatedEntityFn: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => Promise<IPagination<T>>;
	deleteEntityFn: (id: number) => Promise<T>;
	renderListItem: (entity: T) => React.ReactElement;
	getId: (entity: T) => number;
	getSlug: (entity: T) => string;
	navigateEditPath: string;
	navigateCreatePath: string;
	defaultPaginationSettings?: TPaginationSettings;
	disableRemove?: boolean;
};

const EntityItemsPaginatedList = <T,>({
	entityName,
	getPaginatedEntityFn,
	deleteEntityFn,
	renderListItem,
	getId,
	getSlug,
	navigateEditPath,
	navigateCreatePath,
	defaultPaginationSettings,
	disableRemove,
}: EntityItemsPaginatedListProps<T>) => {
	const navigate = useNavigate();
	const [pagination, setPagination] = useState<TPaginationSettings | undefined>(
		defaultPaginationSettings || undefined,
	);
	const [filter, setFilter] = useState<RefsFilter | undefined>(undefined);
	const filterKey = filter ? `name-${filter.name}` : '';
	const paginationKey = pagination
		? `${pagination.page}-${pagination.limit}`
		: '';

	const { data, isError, error, isLoading, refetch } = useQuery<
		IPagination<T>,
		Error
	>([[entityName, paginationKey, filterKey].join('_')], () =>
		getPaginatedEntityFn(filter, pagination),
	);

	useEffect(() => {
		// refetch();
	}, []);

	const entityMutation = useMutation<T, Error, T>((entity) =>
		deleteEntityFn(getId(entity)),
	);

	if (isLoading) {
		return <span>Loading...</span>;
	}

	if (isError) {
		return <span>Error: {error?.message}</span>;
	}

	const openEditEntity = (entity: T) => {
		navigate(`${navigateEditPath}${getSlug(entity)}`);
	};

	const removeEntity = (entity: T) => {
		// eslint-disable-next-line no-alert
		const confirmation = window.confirm(
			`Are you sure you want to delete this ${entityName}?`,
		);
		if (confirmation) {
			entityMutation.mutate(entity, {
				onSuccess() {
					refetch();
				},
			});
		}
	};

	const handleChangePage = (
		_event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => {
		if (data) {
			setPagination({
				page: page + 1,
				limit: data.meta.itemsPerPage,
			});
		}
	};

	return (
		<div>
			<Typography
				component="h1"
				variant="h5"
				style={{ padding: '20px 20px 5px 20px' }}
			>
				{entityName}
				<Button
					variant="contained"
					onClick={() => navigate(navigateCreatePath)}
					sx={{ marginLeft: '15px' }}
				>
					Create new
				</Button>
			</Typography>

			<EntityFilter onFilterChange={setFilter} currentFilter={filter} />

			<ul>
				{data?.items?.map((entity) => (
					<div aria-hidden="true" key={getId(entity)}>
						{renderListItem(entity)}
						<Button onClick={() => openEditEntity(entity)}>Edit</Button>
						<Button disabled={disableRemove} onClick={() => removeEntity(entity)}>
							Delete
						</Button>
					</div>
				))}
			</ul>
			{data && (
				<TablePagination
					rowsPerPageOptions={[10]}
					component="div"
					count={data.meta.totalItems}
					rowsPerPage={data.meta.itemsPerPage}
					page={data.meta.currentPage - 1}
					onPageChange={handleChangePage}
				/>
			)}
		</div>
	);
};

export default EntityItemsPaginatedList;
