import { createSlice } from '@reduxjs/toolkit';

export enum RecentEntities {
	COURSE = 'course',
	TASK = 'task',
	CODE_TASK = 'codeTask',
	QUIZ = 'quiz',
	VIDEO = 'video',
}

type InitialState = {
	course: RecentEntities | null;
	task: RecentEntities | null;
	codeTask: RecentEntities | null;
	quiz: RecentEntities | null;
	video: RecentEntities | null;
};

const initialState = {
	course: null,
	task: null,
	codeTask: null,
	quiz: null,
	video: null,
} as InitialState;

const recentOpenReducer = createSlice({
	name: 'recentOpen',
	initialState,
	reducers: {
		setRecent: (state, action) => {
			state[action.payload.entity as keyof InitialState] = action.payload.link;
		},
		clearAll: (state) => {
			state.course = null;
			state.task = null;
			state.codeTask = null;
			state.quiz = null;
			state.video = null;
		},
	},
});

export const { setRecent, clearAll } = recentOpenReducer.actions;
export default recentOpenReducer;
