import React, { FC, useState } from 'react';
import { FormField } from '../../../lib/interfaces/Form';
import MonacoCodeEditorField from './MonacoCodeEditor';

const DEFAULT_CONTENT = {
	JS: '',
	JS_TESTS: '',
};

type CodeEditorJsWithTestsProps = {
	field: FormField;
	formValues: any;
	handleCodeContentChange: (data: any) => void;
};

const CodeEditorJsWithTests: FC<CodeEditorJsWithTestsProps> = ({
	field,
	formValues,
	handleCodeContentChange,
}) => {
	const isMetaContent = field.metaContent;
	const optionsPath = isMetaContent
		? formValues[field.name]?.value?.options
		: formValues[field.name]?.options;
	const [jsContent, setJsContent] = useState<string>(
		optionsPath?.js || DEFAULT_CONTENT.JS,
	);
	const [jsTestsContent, setJsTestsContent] = useState<string>(
		optionsPath?.jsTests || DEFAULT_CONTENT.JS_TESTS,
	);

	const handleJSEditorChange = (data: string) => {
		setJsContent(data);
		handleCodeContentChange({
			...formValues[field.name]?.options,
			js: data,
			jsTests: jsTestsContent,
		});
	};

	const handleJSTestsEditorChange = (data: string) => {
		setJsTestsContent(data);
		handleCodeContentChange({
			...formValues[field.name]?.options,
			js: jsContent,
			jsTests: data,
		});
	};

	return (
		<div>
			<div style={{ display: 'flex', paddingTop: '15px' }}>
				<div style={{ width: '100%' }}>
					<div style={{ padding: '5px' }}>JS</div>
					<MonacoCodeEditorField
						langauge="javascript"
						height="200px"
						defaultValue={jsContent}
						onChange={(data: string) => {
							handleJSEditorChange(data);
						}}
					/>
				</div>
			</div>
			<div style={{ display: 'flex', paddingTop: '15px' }}>
				<div style={{ width: '100%' }}>
					<div style={{ padding: '5px' }}>JS Tests</div>
					<MonacoCodeEditorField
						langauge="javascript"
						height="500px"
						defaultValue={jsTestsContent}
						onChange={(data: string) => {
							handleJSTestsEditorChange(data);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CodeEditorJsWithTests;
