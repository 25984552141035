import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
	getCodeTaskBySlug,
	updateCodeTask,
} from '../../api/apiRequests/codeTask';
import { useAppDispatch } from '../../hooks/redux';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { ICodeTask } from '../../lib/interfaces/CodeTask';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import {
	codeTaskFields,
	codeTaskInitialContentFields,
} from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { addCustomType } from '../Form/form.helper';

const CodeTaskEdit = () => {
	const { slug = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const dispatch = useAppDispatch();

	const singleCodeTask = useQuery<ICodeTask, Error>([`codeTask-${slug}`], () =>
		getCodeTaskBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleCodeTask;

	const updateCodeTaskMutation = useMutation<ICodeTask, Error, ICodeTask>(
		(formValues: ICodeTask) => updateCodeTask(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateCodeTaskMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Code Task has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	useEffect(() => {
		dispatch(
			setRecent({
				entity: RecentEntities.CODE_TASK,
				link: `edit-code-task/${slug}`,
			}),
		);
	}, [data]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateCodeTaskMutation.mutate(formValues);
		}
	};

	console.log('data', data);
	const modifiedData = data
		? addCustomType(data, codeTaskInitialContentFields)
		: null;

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={modifiedData}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={codeTaskFields}
			entityName="CodeTask"
			contentEntity={CONTENT_ENTITIES.CODE_TASK}
		/>
	) : null;
};

export default CodeTaskEdit;
