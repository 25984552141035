import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteProduct, getAllProducts } from '../../api/apis';
import { IProduct } from '../../lib/interfaces/Product';

const Products = () => {
	const navigate = useNavigate();

	const { data, isError, error, isLoading, refetch } = useQuery<
		IProduct[],
		Error
	>(['products'], () => getAllProducts());

	useEffect(() => {
		refetch();
	}, []);

	const productMutation = useMutation<IProduct, Error, IProduct>((product) =>
		deleteProduct(product.id),
	);

	if (isLoading) {
		return <span>Loading...</span>;
	}

	if (isError) {
		return <span>Error: {error?.message}</span>;
	}

	const openEditProduct = (product: IProduct) => {
		navigate(`/edit-product/${product.slug}`);
	};

	const removeProduct = (product: IProduct) => {
		// eslint-disable-next-line no-alert
		const confirmation = window.confirm(
			'Are you sure you want to delete this product?',
		);
		if (confirmation) {
			productMutation.mutate(product, {
				onSuccess() {
					refetch();
				},
			});
		}
	};

	return (
		<div className="Product__wrapper">
			<Typography
				component="h1"
				variant="h5"
				style={{ padding: '20px 20px 5px 20px' }}
			>
				Products{' '}
				<Button variant="contained" onClick={() => navigate('/create-product')}>
					Create new
				</Button>
			</Typography>

			<ul>
				{data?.map((product) => (
					<div aria-hidden="true" key={product.id}>
						<span>{product.name}</span>
						<Button onClick={() => openEditProduct(product)}>Edit</Button>
						<Button onClick={() => removeProduct(product)}>Delete</Button>
					</div>
				))}
			</ul>
		</div>
	);
};

export default Products;
