import { OrderFilter, TOrderStatusChangeDTO } from '../../lib/interfaces/Order';
import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getOrders: (data: OrderFilter, pagination?: TPaginationSettings | null) => {
		let url = `${BASE_URL}/order-dashboard`;
		if (pagination) {
			const { page, limit } = pagination;
			url = `${BASE_URL}/order-dashboard?page=${page}&limit=${limit}`;
		}
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.POST,
			data,
		};
	},
	changeOrderStatus: (data: TOrderStatusChangeDTO, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/order-dashboard/change-status/${id}`,
		headers: createHeader(null, true),
		data,
	}),
};

export const getOrders = async (
	filter: OrderFilter,
	pagination: TPaginationSettings | null,
) => axiosRequest(endpoints.getOrders(filter, pagination));

export const changeOrderStatus = async (
	data: TOrderStatusChangeDTO,
	id: number | undefined,
) => axiosRequest(endpoints.changeOrderStatus(data, id));
