import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginModalOpen } from '../../redux/reducers/ui/modalsReducer';
import { setCurrentUser } from '../../redux/reducers/ui/userReducer';
import { RootState } from '../../redux/store';

const UserMenu = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentUser = useSelector(
		(state: RootState) => state.ui.user?.currentUser,
	);

	const [userMenu, setUserMenu] = useState<null | HTMLElement>(null);

	const handleUserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setUserMenu(event.currentTarget);
	};

	const handleUserMenuClose = () => {
		setUserMenu(null);
	};

	const logout = async () => {
		setUserMenu(null);
		localStorage.removeItem('user');
		// await doLogout();
		// push("/");
		window.location.href = '/';
	};

	const openSignIn = () => {
		dispatch(setLoginModalOpen(true));
	};

	useEffect(() => {
		if (!currentUser) {
			const data = localStorage.getItem('user');

			if (data) {
				const userData = JSON.parse(data);
				dispatch(setCurrentUser(userData?.user));
			} else {
				openSignIn();
			}
		}
	}, [currentUser, dispatch]);

	return currentUser ? (
		<>
			<Button
				aria-controls="user-menu"
				aria-haspopup="true"
				onClick={handleUserMenuOpen}
			>
				{/* eslint-disable-next-line react/jsx-one-expression-per-line */}
				{t('hey')}, {currentUser.firstName} <ArrowDropDown />
			</Button>
			<Menu
				id="user-menu"
				anchorEl={userMenu}
				keepMounted
				open={Boolean(userMenu)}
				onClose={handleUserMenuClose}
			>
				<MenuItem onClick={logout}>{t('logout')}</MenuItem>
			</Menu>
		</>
	) : (
		<Button variant="contained" onClick={openSignIn}>
			{t('signIn')}
		</Button>
	);
};

export default UserMenu;
