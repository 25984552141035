import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { ICourseOccupation } from '../../lib/interfaces/CourseOccupation';
import { courseOccupationFields } from './CourseOccupationFormFields';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { createCourseOccupation } from '../../api/apiRequests/courseOccupation';

const CourseOccupationCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createCourseOccupationItemMutation = useMutation<
		ICourseOccupation,
		Error,
		ICourseOccupation
	>((formValues) => createCourseOccupation(formValues));
	const { isLoading, isSuccess, error } = createCourseOccupationItemMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['courseOccupations']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createCourseOccupationItemMutation.mutate(formValues);
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={null}
			error={error}
			saveButtonName="Create"
			metaFields={courseOccupationFields}
			entityName="Course Occupation"
			contentEntity={CONTENT_ENTITIES.COURSE_OCCUPATION}
		/>
	);
};

export default CourseOccupationCreate;
