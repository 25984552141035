import React, { FC, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import MonacoCodeEditorField from '../CodeEditor/MonacoCodeEditor';

const languageMapper: Record<string, string> = {
	jsx: 'jsx',
	js: 'javascript',
	html: 'html',
	ts: 'ts',
	tsx: 'tsx',
	css: 'css',
	scss: 'scss',
};

// const getLanguage = (lang: string) => languageMapper[lang] || 'jsx';

type SandPackFileItemProps = {
	item: {
		name: string;
		code: string;
		order: number;
		hidden: boolean;
	};
	handleChange: (
		fileIndex: number | string,
		data: {
			name: string;
			code: string;
			order: number;
			hidden: boolean;
		},
		options: any,
	) => void;
	fileIndex: number | string;
	fieldOptions: any;
	arrayIndex: number;
};

type itemToUpdate = {
	name: string;
	lang: string;
	code: string;
	mainFile: string;
	order: number;
	hidden: boolean;
};

const SandPackFileItem: FC<SandPackFileItemProps> = ({
	item,
	handleChange,
	fileIndex,
	fieldOptions,
	arrayIndex,
}) => {
	const [codeLanguage, setCodeLanguage] = useState<string>(
		fieldOptions?.language?.[item.name] || 'javascript',
	);
	const [itemName, setItemName] = useState<string>(item.name || '');

	const [loading, setLoading] = useState<boolean>(false);

	const [zoom, setZoom] = useState<number>(1);

	const processValue = (item: itemToUpdate) => ({
		name: `${item.name}`,
		code: item.code,
		order: item.order,
		hidden: item.hidden,
	});

	const generateValue = (value: string | boolean, type: string) => {
		const itemToUpdate = {
			name: itemName,
			lang: codeLanguage,
			code: item.code,
			mainFile: fieldOptions?.mainFile,
			order: item.order,
			hidden: item.hidden,
		};
		if (type === 'name') {
			itemToUpdate.name = value as string;
		}
		if (type === 'type') {
			itemToUpdate.lang = value as string;
		}
		if (type === 'code') {
			itemToUpdate.code = value as string;
		}

		if (type === 'mainFile') {
			if (value) {
				itemToUpdate.mainFile = itemName;
			} else if (itemName && fieldOptions.mainFile === itemName) {
				itemToUpdate.mainFile = null;
			}
		}
		if (type === 'hidden') {
			itemToUpdate.hidden = !!value;
		}

		const res = processValue(itemToUpdate as itemToUpdate);
		const previosLangs = fieldOptions?.language || {};
		const options = {
			...fieldOptions,
			language: {
				...previosLangs,
				[itemToUpdate.name]: itemToUpdate.lang,
			},
			mainFile: itemToUpdate.mainFile,
		};
		handleChange(fileIndex, res, options);
	};

	const onChange = (code: string) => {
		generateValue(code, 'code');
	};

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 200);
	}, [codeLanguage]);

	const handleTypeChange = (event: SelectChangeEvent) => {
		setCodeLanguage(event.target.value);
		generateValue(event.target.value, 'type');
	};
	const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setItemName(e.target.value);
		generateValue(e.target.value, 'name');
	};
	const onMainFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		generateValue(e.target.value, 'mainFile');
	};
	const onHiddenFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		generateValue(e.target.checked, 'hidden');
	};
	const handleZoom = () => {
		if (zoom < 4) {
			setZoom((prev) => prev + 1);
		} else {
			setZoom(1);
		}
	};
	const getHeight = () => `${zoom * 150}px`;

	return (
		<div>
			<div style={{ padding: '5px 0' }}>Sandpack file {arrayIndex + 1} </div>

			<FormGroup
				style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10 }}
			>
				<TextField onChange={onNameChange} value={itemName} size="small" />
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={codeLanguage}
					onChange={handleTypeChange}
					size="small"
				>
					{!!languageMapper &&
						Object.entries(languageMapper).map(
							([option, value]: [string, string]) => (
								<MenuItem key={option} value={value}>
									{option}
								</MenuItem>
							),
						)}
				</Select>

				<FormControlLabel
					control={
						<Checkbox
							name={`${fileIndex}_0`}
							checked={fieldOptions?.mainFile === itemName}
							onChange={onMainFileChange}
						/>
					}
					label="Main file"
				/>
				<FormControlLabel
					control={
						<Checkbox
							name={`${fileIndex}_hidden_0`}
							checked={item.hidden}
							value={item.hidden}
							onChange={onHiddenFileChange}
						/>
					}
					label="Hidden"
				/>
				<Button onClick={handleZoom} startIcon={<ZoomInIcon />}>
					Zoom {zoom * 25}%
				</Button>
			</FormGroup>
			{loading ? (
				<div>Loading...</div>
			) : (
				<MonacoCodeEditorField
					langauge={codeLanguage}
					defaultValue={item.code || ''}
					onChange={onChange}
					height={getHeight()}
				/>
			)}
		</div>
	);
};

export default SandPackFileItem;
