import React, { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { FormField } from '../../lib/interfaces/Form';
import { CUSTOM_FIELD_MENU_OPTION } from './formUtils';

type FastReorderCustomFieldProps = {
	handleCustomFieldAction: (data: any) => void;
	field: FormField;
	fieldIndex: number;
	fieldsLength: number;
};

const FastReorderCustomField: FC<FastReorderCustomFieldProps> = ({
	handleCustomFieldAction,
	field,
	fieldIndex,
	fieldsLength,
}) => {
	const onMenuOptionSelect = (option: string) => {
		handleCustomFieldAction({ option, field });
	};

	return (
		<div>
			{fieldIndex >= 2 && (
				<IconButton
					onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.MOVE_UP)}
					sx={{
						width: '14px',
						height: '14px',
						color: 'white',
						marginRight: '10px',
					}}
					size="small"
				>
					<ArrowCircleUpIcon />
				</IconButton>
			)}
			{fieldIndex < fieldsLength - 1 && (
				<IconButton
					onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.MOVE_DOWN)}
					sx={{
						width: '14px',
						height: '14px',
						color: 'white',
						marginRight: '10px',
					}}
				>
					<ArrowCircleDownIcon />
				</IconButton>
			)}
		</div>
	);
};

export default FastReorderCustomField;
