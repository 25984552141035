import { IProductDTO } from '../lib/interfaces/Product';
import { Task } from '../lib/interfaces/Task';
import { UserDTO } from '../lib/interfaces/User';

const getBaseEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'production':
			return 'https://api.codeway.today/api';
		case 'staging':
			return 'https://stg-api.codeway.today/api';
		default:
			return 'http://localhost:4000/api';
	}
};

export const BASE_URL = getBaseEnv();

/**
 * List of Content-types.
 * You can add more if needed.
 *
 * @enum {string}
 */
export const contentTypes = {
	APPLICATION_JSON: 'application/json',
	MULTIPART_FORMDATA: 'multipart/form-data',
};

export const API_METHODS = {
	GET: 'GET',
	POST: 'POST',
	PATCH: 'PATCH',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

/**
 * Creates header from data
 *
 * @param {(contentTypes|string)} contentType One of the types from contentTypes list or string
 * @param {boolean} [hasToken=true] - If true (by default) token will be added to Header object
 * @returns {object} Header object with "Content-Type" and "Authorization" fields.
 *
 */
export const createHeader = (contentType: string | null, hasToken = true) => {
	const headerResult: {
		Authorization?: string;
		['Content-Type']?: string;
	} = {};

	if (hasToken) {
		const userFromLS: string | null = localStorage.getItem('user');
		if (userFromLS) {
			const currentUser = JSON.parse(userFromLS);
			// eslint-disable-next-line no-unused-expressions
			currentUser?.token &&
				(headerResult.Authorization = `Bearer ${currentUser.token}`);
		}
	}

	headerResult['Content-Type'] = contentType || contentTypes.APPLICATION_JSON;

	return headerResult;
};

// TODO: move everything to separate files
/**
 * Enpoints Constructor
 *
 */
export const endpoints = {
	auth: {
		getLoginApi: (data: UserDTO) => ({
			headers: createHeader(null, false),
			url: `${BASE_URL}/auth/login`,
			method: API_METHODS.POST,
			data,
		}),
	},
	task: {
		getTaskById: (slug: string) => ({
			headers: createHeader(null, true),
			url: `${BASE_URL}/task/${slug}`,
			method: API_METHODS.GET,
		}),

		createTask: (data: Task) => ({
			method: API_METHODS.POST,
			url: `${BASE_URL}/task`,
			headers: createHeader(null, true),
			data,
		}),

		updateTask: (data: Task, id: number | undefined) => ({
			method: API_METHODS.PATCH,
			url: `${BASE_URL}/task/${id}`,
			headers: createHeader(null, true),
			data,
		}),

		deleteTask: (id: number | undefined) => ({
			method: API_METHODS.DELETE,
			url: `${BASE_URL}/task/${id}`,
			headers: createHeader(null, true),
		}),
	},
	product: {
		getProductBySlug: (slug: string) => ({
			headers: createHeader(null, true),
			url: `${BASE_URL}/product/${slug}`,
			method: API_METHODS.GET,
		}),

		getAllProducts: () => ({
			headers: createHeader(null, true),
			url: `${BASE_URL}/product/dashboard/products`,
			method: API_METHODS.GET,
		}),

		createProduct: (data: IProductDTO) => ({
			method: API_METHODS.POST,
			url: `${BASE_URL}/product`,
			headers: createHeader(null, true),
			data,
		}),

		updateProduct: (data: Partial<IProductDTO>, id: number | undefined) => ({
			method: API_METHODS.PATCH,
			url: `${BASE_URL}/product/${id}`,
			headers: createHeader(null, true),
			data,
		}),

		deleteProduct: (id: number | undefined) => ({
			method: API_METHODS.DELETE,
			url: `${BASE_URL}/product/${id}`,
			headers: createHeader(null, true),
		}),
	},
	file: {
		uploadFile: (data: FormData) => ({
			method: API_METHODS.POST,
			url: `${BASE_URL}/common/upload-file/`,
			headers: createHeader('multipart/form-data', true),
			data,
		}),
		uploadVideo: (data: FormData) => ({
			method: API_METHODS.POST,
			url: `${BASE_URL}/common/upload-video/`,
			headers: createHeader('multipart/form-data', true),
			data,
		}),
	},
};
