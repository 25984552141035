import { PRODUCTS, PRODUCT_FORM_TYPES } from '../../lib/helpers/consts';
import { FormField } from '../../lib/interfaces/Form';
import { IMetadata } from '../../lib/interfaces/Metadata';
import { IProduct } from '../../lib/interfaces/Product';
import { FIELD_TYPES } from '../Form/formUtils';

const PRODUCT_TYPES = [
	{
		label: 'Service',
		value: 1,
	},
	{
		label: 'Subscription Plan',
		value: 2,
	},
];

const PRODUCT_DETAILS_FORM_IDS = [
	{
		label: 'Interview',
		value: PRODUCT_FORM_TYPES.INTERVIEW,
	},
	{
		label: 'Consultation',
		value: PRODUCT_FORM_TYPES.CONSULTATION,
	},
	{
		label: 'Exercise review',
		value: PRODUCT_FORM_TYPES.EXERCISE_REVIEW,
	},
	{
		label: 'Subscription plan',
		value: PRODUCT_FORM_TYPES.SUBSCRIPTION_PLANS,
	},
];

export const productFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'type',
		init: 1,
		label: 'Type',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: PRODUCT_TYPES,
	},
	{
		name: 'detailsFormId',
		init: PRODUCTS.MOCK_INTERVIEW,
		label: 'Form Details',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: PRODUCT_DETAILS_FORM_IDS,
	},
	{
		name: 'mappedSubscriptionPlan',
		init: null,
		label: 'Mapped subscription plan',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: PRODUCT_DETAILS_FORM_IDS,
	},
	{
		name: 'price',
		init: [],
		label: 'Price',
		required: true,
		type: FIELD_TYPES.TEXT,
		freeSolo: false,
		inputType: 'number',
	},
	{
		name: 'isPublished',
		init: false,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	{
		name: 'imageUrl',
		init: '',
		label: 'Image URL',
		required: true,
		type: FIELD_TYPES.FILE,
	},
	{
		name: 'sortNumber',
		init: [],
		label: 'Product sort order',
		required: true,
		type: FIELD_TYPES.TEXT,
		freeSolo: false,
		inputType: 'number',
	},
];

export const getProductFields = (metadata: IMetadata, data?: IProduct) => {
	const fields = productFields.map((field) => {
		if (field.name === 'mappedSubscriptionPlan') {
			const plans = metadata.subscriptionPlans.map((plan) => ({
				label: plan.name,
				value: plan.id,
			}));
			field.options = plans;
			if (data) {
				field.init = data.mappedSubscriptionPlan;
			}
		}
		return field;
	});
	return fields;
};
