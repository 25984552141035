import { ILegalDTO } from '../../lib/interfaces/Legal';
import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getLegalBySlug: (slug: string) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/legal-dashboard/${slug}`,
		method: API_METHODS.GET,
	}),

	getPaginatedLegals: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/legal-dashboard`,
			pagination,
			filter,
		);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createLegal: (data: ILegalDTO) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/legal-dashboard`,
		headers: createHeader(null, true),
		data,
	}),

	updateLegal: (data: Partial<ILegalDTO>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/legal-dashboard/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteLegal: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/legal-dashboard/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getLegalBySlug = async (slug: string) =>
	axiosRequest(endpoints.getLegalBySlug(slug));

export const getPaginatedLegals = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedLegals(filter, pagination));

export const createLegal = async (data: ILegalDTO) =>
	axiosRequest(endpoints.createLegal(data));

export const updateLegal = async (
	data: Partial<ILegalDTO>,
	id: number | undefined,
) => axiosRequest(endpoints.updateLegal(data, id));

export const deleteLegal = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteLegal(id));
