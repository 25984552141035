import { IEntityContent } from './Form';

// eslint-disable-next-line no-shadow
export enum QuizQuestionTypes {
	OPTIONS = 1,
	MULTIPLE_OPTIONS = 2,
	EXACT_ANSWER = 3,
}

// eslint-disable-next-line no-shadow
export enum QuizTypes {
	QUIZ = 1,
	EXAM = 2,
}

export interface QuizOption {
	text: string;
	id: string;
}

export interface QuizQuestion {
	id: string;
	text?: string;
	type?: number;
	answer?: string[];
	options?: QuizOption[];
}

/* export interface QuizContent {
	[key: string]: {
		name: string;
		description: string;
		questions: QuizQuestion[];
	};
} */

export interface QuizDTO {
	name: string;
	type: QuizTypes;
	content: IEntityContent;
}

export interface IQuiz {
	id: number;
	name: string;
	slug: string;
	type: QuizTypes;
	content: IEntityContent;
}

export interface IQuizLightRO {
	id: number;
	name: string;
	slug: string;
}
