import cloneDeep from 'lodash/cloneDeep';
import { Course } from '../../lib/interfaces/Course';
import { FormField } from '../../lib/interfaces/Form';
import { IMetadata } from '../../lib/interfaces/Metadata';
import { FIELD_TYPES } from '../Form/formUtils';
import { generateOptions } from '../FormFields/Autocomplete/autocompleteUtils';
import { OptionEntity } from '../FormFields/Tags';

const CATEGORIES: OptionEntity[] = [
	{ label: 'Practice', id: 'practice' },
	{ label: 'Theory', id: 'theory' },
];

const TAGS: OptionEntity[] = [
	{ label: 'HTML', id: 'HTML' },
	{ label: 'CSS', id: 'CSS' },
	{ label: 'SCSS', id: 'SCSS' },
	{ label: 'Javascript', id: 'Javascript' },
	{ label: 'React', id: 'React' },
	{ label: 'Exercises', id: 'Exercises' },
	{ label: 'REST API', id: 'REST API' },
	{ label: 'Redux', id: 'Redux' },
	{ label: 'Practice', id: 'Practice' },
	{ label: 'Tutorial', id: 'Tutorial' },
	{ label: 'Interview', id: 'Interview' },
	{ label: 'HR', id: 'HR' },
	{ label: 'Soft Skills', id: 'Soft Skills' },
];

export const courseFields: FormField[] = [
	{
		name: 'isPublished',
		init: false,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'customClass',
		init: '',
		label: 'Custom CSS class',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: true,
		defaultOptions: [
			{
				label: 'cwt-course-free',
				id: 'cwt-course-free',
			},
			{
				label: 'cwt-course-new',
				id: 'cwt-course-new',
			},
		],
	},
	{
		name: 'imageUrl',
		init: '',
		label: 'Image URL',
		required: true,
		type: FIELD_TYPES.FILE,
	},
	{
		name: 'category',
		init: [],
		label: 'Categories',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: false,
		defaultOptions: CATEGORIES,
	},
	{
		name: 'occupationIds',
		init: [],
		label: 'Occupation',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: false,
		readOnly: true,
	},
	{
		name: 'tags',
		init: [],
		label: 'Tags',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: true,
		defaultOptions: TAGS,
	},
];

export const courseContentFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'description',
		init: '',
		label: 'Description',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
];

export const getCourseFields = (metadata: IMetadata, data?: Course) => {
	const fields = cloneDeep(courseFields);
	fields.forEach((field) => {
		if (field.name === 'occupationIds') {
			field = generateOptions({
				selectedIds: data?.occupationIds,
				optionsList: metadata?.courseOccupations,
				labelKey: 'name',
				idKey: 'id',
				field,
			});
		}
		if (field.name === 'tags') {
			field = generateOptions({
				selectedIds: data?.tags,
				field,
			});
		}
		if (field.name === 'category') {
			field = generateOptions({
				selectedIds: data?.category,
				field,
			});
		}
		if (field.name === 'customClass' && data) {
			field = generateOptions({
				selectedIds: data.customClass,
				optionsList: field.defaultOptions,
				field,
			});
		}
	});
	return fields;
};
