import React, { FC, useState } from 'react';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import { INPUT_TYPES } from '../../Form/formUtils';
import InputTextField from '../../Form/InputTextField';
import CKEditorField from '../CKEditor';
import SandPackWrapper from '../SandPack/SandPack';

type TextContentWrapperFieldProps = {
	field: FormField;
	formValues: any;

	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const TextContentWrapperField: FC<TextContentWrapperFieldProps> = ({
	field,
	formValues,

	handleNamedContentFieldChange,
}) => {
	const [contentBefore, setContentBefore] = useState<string>(
		formValues[field.name]?.options?.contentBefore || '',
	);
	const [contentAfter, setContentAfter] = useState<string>(
		formValues[field.name]?.options?.contentAfter || '',
	);
	const [heading, setHeading] = useState<string>(
		formValues[field.name]?.options?.heading || '',
	);

	// Heading
	const handleChangeHeading = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHeading(e.target.value);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					heading: e.target.value,
				},
			},
			field.name,
		);
	};

	// Content Before
	const handleContentBeforeChange = (data: string) => {
		setContentBefore(data);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					contentBefore: data,
				},
			},
			field.name,
		);
	};

	// Content Before
	const handleContentAfterChange = (data: string) => {
		setContentAfter(data);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					contentAfter: data,
				},
			},
			field.name,
		);
	};

	return (
		<div>
			{field.custom && <div style={{ marginBottom: '15px' }}>Title</div>}
			{field.custom && (
				<InputTextField
					onChange={handleChangeHeading}
					variant="outlined"
					fullWidth
					label="Title"
					name={field.name}
					type={field.inputType || INPUT_TYPES.TEXT}
					defaultValue={heading}
					// className={validation[field.name] ? 'invalid' : ''}
					fieldInputType={field.inputType}
				/>
			)}
			<CKEditorField
				{...field}
				label="Description"
				defaultValue={contentBefore}
				onChange={(data: string) => {
					handleContentBeforeChange(data);
				}}
			/>
			<SandPackWrapper
				field={field}
				formValues={formValues}
				// language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
			<CKEditorField
				{...field}
				label="Content after"
				defaultValue={contentAfter}
				onChange={(data: string) => {
					handleContentAfterChange(data);
				}}
			/>
		</div>
	);
};

export default TextContentWrapperField;
