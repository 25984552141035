// Libraries
import { Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { OrderObject } from '../../../lib/helpers/consts';

// CSS
import './DragAndDropList.scss';

type DragAndDropListProps = {
	items: any[];
	idFieldName?: string;
	onOrderChange: (payload: OrderObject) => void;
	onRemove?: (id: number) => void;
};

const DragAndDropList: FC<DragAndDropListProps> = ({
	items,
	onOrderChange,
	idFieldName,
	onRemove,
}) => {
	console.log('d1 items: ', items);
	const [currentItemsOrder, setCurrentItemsOrder] = useState<any[]>([]);

	useEffect(() => {
		setCurrentItemsOrder(items);
	}, [items]);

	const onDragEnd = (result: any) => {
		if (!result.destination) return;

		if (currentItemsOrder?.length) {
			const reorderedItems = [...currentItemsOrder];
			const [movedItem] = reorderedItems.splice(result.source.index, 1);
			reorderedItems.splice(result.destination.index, 0, movedItem);

			setCurrentItemsOrder(reorderedItems);
			const newItemsOrder: OrderObject = reorderedItems.reduce(
				(acc, task, idx) => ({
					...acc,
					[idx + 1]: task.id,
				}),
				{},
			);
			onOrderChange(newItemsOrder);
		}
	};

	return (
		<div className="DragAndDropList__container">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="DragAndDropList">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{currentItemsOrder?.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id.toString()} index={index}>
									{(providedDraggble, snapshot) => (
										<div
											className="DragAndDropList__item"
											ref={providedDraggble.innerRef}
											{...providedDraggble.draggableProps}
											{...providedDraggble.dragHandleProps}
										>
											<div
												className={`DragAndDropList__item__wrapper ${
													snapshot.isDragging ? 'dragging' : ''
												}`}
											>
												<div>{item.name}</div>
												{!!onRemove && idFieldName && (
													<div>
														<Button
															size="small"
															onClick={() => {
																onRemove(item[idFieldName]);
															}}
														>
															Remove
														</Button>
													</div>
												)}
											</div>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default DragAndDropList;
