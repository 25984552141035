/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { FC, useState } from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	// InputLabel,
	MenuItem,
	// OutlinedInput,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuidv4 } from 'uuid';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import SandPackFileItem from './SandPackFileItem';
import SandPackDependencies from './SandPackDependencies';

type SandPackWrapperProps = {
	field: FormField;
	formValues: any;
	language?: string;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

type FileContent = {
	name: string;
	code: string;
	order: number;
	hidden: boolean;
};
type FileTypeList = {
	[key: number | string]: FileContent;
};

type DependencyType = {
	[key: string]: string;
};

const SandPackWrapper: FC<SandPackWrapperProps> = ({
	field,
	formValues,
	language,
	handleNamedContentFieldChange,
}) => {
	console.log(language, formValues[field.name]);
	const parsed = formValues[field.name]?.value
		? JSON.parse(formValues[field.name]?.value)
		: null;
	const [files, setFiles] = useState<FileTypeList>(
		parsed || {
			[uuidv4()]: {
				name: '',
				code: '',
				order: 0,
				hidden: false,
			},
		},
	);

	const updateFormState = (updatedFiles: FileTypeList, options: any) => {
		handleNamedContentFieldChange(
			{
				value: JSON.stringify(updatedFiles),
				options: {
					...formValues[field.name]?.options,
					...options,
				},
			},
			field.name,
		);
	};

	const cleanLanguagesBeforeSave = (
		updatedFiles: {
			[x: string]: FileContent;
		},
		options: {
			language: Record<string, string>;
		},
	) => {
		const availableFiles: string[] = Object.values(updatedFiles).map(
			(file) => file.name,
		);
		Object.keys(options.language).forEach((key: string) => {
			if (!availableFiles.includes(key)) {
				delete options.language[key];
			}
		});
		return options;
	};

	const handleChange = (
		fileIndex: number | string,
		item: FileContent,
		options: any,
	) => {
		const updatedFiles = {
			...files,
			[fileIndex]: item,
		};
		updateFormState(
			updatedFiles,
			cleanLanguagesBeforeSave(updatedFiles, options),
		);
		setFiles(updatedFiles);
	};

	const addBlock = () => {
		// const lastEl = Object.keys(files).length;
		const updatedFiles = {
			...files,
			[uuidv4()]: {
				name: '',
				code: '',
				order: Object.keys(files).length,
				hidden: false,
			},
		};
		updateFormState(updatedFiles, formValues[field.name]?.options);
		setFiles(updatedFiles);
	};

	const onSnippetModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateFormState(files, {
			...formValues[field.name]?.options,
			snippetMode: e.target.checked,
		});
	};

	const onConsoleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateFormState(files, {
			...formValues[field.name]?.options,
			consoleMode: e.target.checked,
		});
	};

	const handleTemplateChange = (event: SelectChangeEvent) => {
		updateFormState(files, {
			...formValues[field.name]?.options,
			template: event.target.value,
		});
	};

	const onDependencyChange = (deps: DependencyType) => {
		updateFormState(files, {
			...formValues[field.name]?.options,
			dependencies: deps,
		});
	};

	const onRemove = (key: string | number) => {
		const updatedFiles = { ...files };
		delete updatedFiles[key];

		updateFormState(
			updatedFiles,
			cleanLanguagesBeforeSave(updatedFiles, {
				...formValues[field.name]?.options,
			}),
		);
		setFiles(updatedFiles);
	};
	// const moveUp = (key: string | number) => {
	// 	const updatedFiles = { ...files };
	// 	const keys = Object.keys(updatedFiles);

	// 	const keyToMoveUp = keys.findIndex((item: string) => item === key);
	// 	if (keyToMoveUp > 0) {
	// 		const keyToMoveDown = keyToMoveUp - 1;
	// 	}

	// 	updateFormState(
	// 		updatedFiles,
	// 		cleanLanguagesBeforeSave(updatedFiles, {
	// 			...formValues[field.name]?.options,
	// 		}),
	// 	);
	// 	setFiles(updatedFiles);
	// };

	const availableTemplates = ['react', 'static', 'vanilla'];
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
				<FormControlLabel
					control={
						<Checkbox
							// {...field}
							name="Snipet mode"
							checked={formValues[field.name]?.options?.snippetMode}
							onChange={onSnippetModeChange}
						/>
					}
					label="Snippet mode"
				/>
				<FormControl
					sx={{
						m: 1,

						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					{/* <InputLabel id="template-selection">Template</InputLabel> */}
					<div style={{ borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>
						Template:{' '}
					</div>
					<Select
						labelId="template-selection"
						value={formValues[field.name]?.options?.template || availableTemplates[0]}
						onChange={handleTemplateChange}
						// input={<OutlinedInput label="Status" />}
						size="small"
						variant="outlined"
						// MenuProps={MenuProps}
					>
						{availableTemplates.map((value) => (
							<MenuItem value={value} key={value}>
								{value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControlLabel
					control={
						<Checkbox
							// {...field}
							name="Show console by default"
							checked={formValues[field.name]?.options?.consoleMode}
							onChange={onConsoleModeChange}
						/>
					}
					label="Show console by default"
				/>
			</div>
			{Object.entries(files).map(([key, value], index) => (
				<div
					key={key}
					style={{
						border: '1px solid #ccc',
						padding: '15px',
						position: 'relative',
					}}
				>
					<div>
						<div style={{ position: 'absolute', top: '10px', right: '10px' }}>
							<Button
								startIcon={<DeleteForeverIcon />}
								onClick={() => onRemove(key)}
								sx={{ marginLeft: '25px' }}
							>
								Remove
							</Button>
						</div>
					</div>
					<SandPackFileItem
						fileIndex={key}
						arrayIndex={index}
						item={value}
						fieldOptions={formValues[field.name]?.options}
						handleChange={handleChange}
					/>
				</div>
			))}
			<Button onClick={addBlock}>+ Add</Button>
			<br />
			<SandPackDependencies
				onDependencyChange={onDependencyChange}
				dependencies={formValues[field.name]?.options?.dependencies}
			/>
		</div>
	);
};

export default SandPackWrapper;
