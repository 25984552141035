import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { IVideoItem } from '../../lib/interfaces/VideoItem';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getVideoItemById: (id: number) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/video/${id}`,
		method: API_METHODS.GET,
	}),

	getAllVideoItems: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/video`,
		method: API_METHODS.GET,
	}),

	getPaginatedVideoItems: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(`${BASE_URL}/video`, pagination, filter);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createVideoItem: (data: IVideoItem) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/video`,
		headers: createHeader(null, true),
		data,
	}),

	updateVideoItem: (data: Partial<IVideoItem>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/video/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteVideoItem: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/video/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getVideoItemById = async (id: number) =>
	axiosRequest(endpoints.getVideoItemById(id));

export const getAllVideoItems = async () =>
	axiosRequest(endpoints.getAllVideoItems());

export const getPaginatedVideoItems = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedVideoItems(filter, pagination));

export const createVideoItem = async (data: IVideoItem) =>
	axiosRequest(endpoints.createVideoItem(data));

export const updateVideoItem = async (
	data: Partial<IVideoItem>,
	id: number | undefined,
) => axiosRequest(endpoints.updateVideoItem(data, id));

export const deleteVideoItem = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteVideoItem(id));
