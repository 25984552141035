// import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../lib/interfaces/Form';
import {
	ERRORS,
	vEmail,
	vHasSpecialCharacters,
	vMaxLength,
	vMinLength,
	vPassword,
	vShouldMatch,
} from './form.validations';
import { FIELD_TYPES, INPUT_TYPES } from './formUtils';

export const useFormValidation = () => {
	const { t } = useTranslation();
	const invalidFields: any = {};
	const validate = (
		fields: FormField[],
		formValues: any,
		isContentForm = true,
	): { [key: string]: string } => {
		fields.forEach((field: FormField) => {
			const value = isContentForm
				? formValues[field.name]?.value
				: formValues[field.name];

			if (field?.inputType === INPUT_TYPES.EMAIL && !vEmail(value)) {
				invalidFields[field.name] = t(ERRORS.INVALID, {
					fieldName: t(field.label),
				});
			}
			const minLength = field.validation?.minLength;
			const maxLength = field.validation?.maxLength;
			if (minLength && !vMinLength(value, minLength)) {
				invalidFields[field.name] = t(ERRORS.MIN_LENGTH, {
					number: minLength,
				});
			}
			const allowSpecialCharacters =
				field.validation?.allowSpecialCharacters !== undefined
					? field.validation?.allowSpecialCharacters
					: true;
			if (!allowSpecialCharacters && vHasSpecialCharacters(value)) {
				invalidFields[field.name] = t(ERRORS.INVALID, {
					fieldName: t(field.label),
				});
			}
			if (field.inputType === INPUT_TYPES.PASSWORD) {
				const validatePW: string[] = vPassword(value, field?.validation?.password);
				if (validatePW.length) {
					const errorTranslations = validatePW.map((error: string) => t(error));
					invalidFields[field.name] = t(ERRORS.PASSWORD_INVALID, {
						errors: errorTranslations.join(', '),
					});
				}
				const confirmPasswordValidation = field?.validation?.shouldMatch?.field;
				if (
					confirmPasswordValidation &&
					!vShouldMatch(value, formValues[confirmPasswordValidation])
				) {
					invalidFields[field.name] = t(ERRORS.PASSWORD_DOESNT_MATCH);
				}
			}
			if (maxLength && !vMaxLength(value, maxLength)) {
				invalidFields[field.name] = t(ERRORS.MAX_LENGTH, {
					number: maxLength,
				});
			}
			if (field?.required) {
				if (
					(field.type === FIELD_TYPES.TEXT && !value) ||
					(field.type === FIELD_TYPES.MULTISELECT && !value.length) ||
					(field.type === FIELD_TYPES.RICH_EDITOR && !value)
				) {
					invalidFields[field.name] = t(ERRORS.REQUIRED);
				}
			}
		});
		return invalidFields;
	};
	return { validate };
};
export default useFormValidation;
