import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Checkbox } from '@mui/material';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../lib/interfaces/Form';
import { IQuiz } from '../../lib/interfaces/Quiz';
import { getAllQuizzes } from '../../api/apiRequests/quiz';
import RefSelectorField from '../FormFields/RefSelector/RefSelectorField';
import { RefType, getDefaultLanguageFieldName } from '../Form/formUtils';

type QuizFieldInTaskProps = {
	field: FormField;
	formValues: any;
	// validation?: any;
	language: string;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const QuizFieldInTask: FC<QuizFieldInTaskProps> = ({
	field,
	formValues,
	language,
	handleNamedContentFieldChange,
}) => {
	const { data } = useQuery<IQuiz[], Error>(['quizzes'], () => getAllQuizzes());

	const handleRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					required: e.target.checked,
				},
				reference: formValues[field.name]?.reference,
			},
			field.name,
		);
	};

	const defaultLanguageFieldName = getDefaultLanguageFieldName(field);

	return (
		<div>
			{/* <div style={{ marginBottom: '15px' }}>Quiz</div> */}
			{!!data && (
				<RefSelectorField
					field={field}
					formValues={formValues}
					language={language}
					refType={RefType.QUIZ_REF}
					handleNamedContentFieldChange={handleNamedContentFieldChange}
				/>
			)}
			{formValues[defaultLanguageFieldName]?.reference?.name && (
				<div>
					<Checkbox
						checked={formValues[field.name]?.options?.required}
						onChange={handleRequiredChange}
					/>{' '}
					Required to complete
				</div>
			)}
		</div>
	);
};

export default QuizFieldInTask;
