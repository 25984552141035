import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createCourse } from '../../api/apiRequests/course';
import useGetMetadataQuery from '../../hooks/useGetMetadata';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { Course } from '../../lib/interfaces/Course';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { getCourseFields } from './courseFormFields';

const CourseCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { data: metadata } = useGetMetadataQuery();

	const createCourseMutation = useMutation<Course, Error, Course>((formValues) =>
		createCourse(formValues),
	);
	const { isLoading, isSuccess, error } = createCourseMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['courses']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => createCourseMutation.mutate(formValues);

	return metadata ? (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={null}
			error={error}
			saveButtonName="Create"
			metaFields={getCourseFields(metadata)}
			entityName="Course"
			contentEntity={CONTENT_ENTITIES.COURSE}
		/>
	) : null;
};

export default CourseCreate;
