/* eslint-disable react/no-danger */
import { FC, useState } from 'react';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import clip from 'text-clipper';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import QuizQuestionEditor from './QuizQuestionEditor';
import htmlQuizOptionSanitizer from '../../../lib/helpers/html.helper';
import { swapElements } from '../../../lib/helpers/general.helper';

import './QuizEditorField.scss';

type QuizEditorFieldProps = {
	field: FormField;
	formValues: any;
	handleQuizEditorChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const QuizEditorField: FC<QuizEditorFieldProps> = ({
	field,
	formValues,
	handleQuizEditorChange,
}) => {
	const [questions, setQuestions] = useState<any[]>(
		formValues[field.name]?.options?.questions || [],
	);
	const [expanded, setExpanded] = useState<string[]>([]);

	const handleQuizChange = (updatedQuestions: any) => {
		handleQuizEditorChange(
			{
				value: 'questions',
				options: {
					questions: updatedQuestions,
				},
			},
			field.name,
		);
	};

	const addQuestion = () => {
		const newQuestion = {
			id: uuidv4(),
		};
		const updatedQuestions = [...questions, newQuestion];
		setQuestions(updatedQuestions);
		handleQuizChange(updatedQuestions);
		setExpanded([...expanded, newQuestion.id]);
	};

	const updateQuestion = (updatedQuestion: any) => {
		let updatedQuestions = [...questions];
		updatedQuestions = updatedQuestions.map((q: any) => {
			if (q.id === updatedQuestion.id) {
				return updatedQuestion;
			}
			return q;
		});
		setQuestions(updatedQuestions);
		handleQuizChange(updatedQuestions);
	};

	const removeQuestion = (id: string) => {
		const updatedQuestions = questions.filter((q) => q.id !== id);
		setQuestions(updatedQuestions);
		handleQuizChange(updatedQuestions);
	};

	const moveQuestion = (up: boolean, id: string) => {
		let updatedQuestions = [...questions];
		const index = updatedQuestions.findIndex((q) => q.id === id);
		const swappedIndex = up ? index - 1 : index + 1;
		updatedQuestions = swapElements(updatedQuestions, index, swappedIndex);
		setQuestions(updatedQuestions);
		handleQuizChange(updatedQuestions);
	};

	const expandQuestion = (id: string) => {
		const updatedExpanded = [...expanded];
		const index = updatedExpanded.findIndex((el) => el === id);
		if (index > -1) {
			updatedExpanded.splice(index, 1);
		} else {
			updatedExpanded.push(id);
		}
		setExpanded(updatedExpanded);
	};

	const getTruncatedText = (text: string) => {
		const sanitizedText = htmlQuizOptionSanitizer(text);
		return { __html: clip(sanitizedText, 60) };
	};

	return (
		<div>
			<div style={{ marginBottom: '15px' }}>Questions</div>
			{questions &&
				questions.map((question: any, index: number) => (
					<div key={question.id}>
						<Paper elevation={0} variant="outlined" style={{ marginBottom: '5px' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									padding: '10px',
								}}
							>
								<Typography
									component="div"
									variant="body1"
									className="question-accordion"
								>
									<div style={{ display: 'flex' }}>
										<div>{index + 1}. </div>
										<span dangerouslySetInnerHTML={getTruncatedText(question?.text)} />
									</div>
								</Typography>
								<div style={{ display: 'flex' }}>
									<IconButton
										aria-label="delete"
										size="medium"
										onClick={() => removeQuestion(question.id)}
									>
										<DeleteForeverIcon fontSize="inherit" />
									</IconButton>
									{index > 0 && (
										<IconButton
											aria-label="delete"
											size="medium"
											onClick={() => moveQuestion(true, question.id)}
										>
											<ArrowCircleUpIcon fontSize="inherit" />
										</IconButton>
									)}
									{index < questions.length - 1 && (
										<IconButton
											aria-label="delete"
											size="medium"
											onClick={() => moveQuestion(false, question.id)}
										>
											<ArrowCircleDownIcon fontSize="inherit" />
										</IconButton>
									)}
									<IconButton
										aria-label="delete"
										size="medium"
										onClick={() => expandQuestion(question.id)}
									>
										<ArrowDropDownIcon fontSize="inherit" />
									</IconButton>
								</div>
							</div>
							<div
								style={{
									overflow: 'hidden',
									height: expanded.includes(question.id) ? 'auto' : 0,
								}}
							>
								<div
									style={{
										padding: '15px',
										borderLeft: '20px solid #ccc',
									}}
								>
									<QuizQuestionEditor
										question={question}
										updateQuestion={updateQuestion}
									/>
								</div>
							</div>
						</Paper>
					</div>
				))}
			<Button onClick={addQuestion}>Add Question</Button>
		</div>
	);
};

export default QuizEditorField;
