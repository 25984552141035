import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	ISubscriptionPlan,
	ISubscriptionPlanDTO,
	ISubscriptionPlanRO,
} from '../../lib/interfaces/SubscriptionPlan';
import {
	deleteSubscriptionPlan,
	getAllSubscriptionPlans,
} from '../../api/apiRequests/subscriptionPlans';

const SubscriptionPlans = () => {
	const navigate = useNavigate();

	const { data, isError, error, isLoading, refetch } = useQuery<
		ISubscriptionPlan[],
		Error
	>(['subscriptionPlans'], () => getAllSubscriptionPlans());

	useEffect(() => {
		refetch();
	}, []);

	const subscriptionPlanMutation = useMutation<
		ISubscriptionPlanRO,
		Error,
		Partial<ISubscriptionPlanDTO>
	>((plan) => deleteSubscriptionPlan(plan.id));

	if (isLoading) {
		return <span>Loading...</span>;
	}

	if (isError) {
		return <span>Error: {error?.message}</span>;
	}

	const openEditSubscriptionPlan = (subscriptionPlan: ISubscriptionPlan) => {
		navigate(`/edit-subscription-plan/${subscriptionPlan.slug}`);
	};

	const removeSubscriptionPlan = (subscriptionPlan: ISubscriptionPlan) => {
		// eslint-disable-next-line no-alert
		const confirmation = window.confirm(
			'Are you sure you want to delete this subscription plan?',
		);
		if (confirmation) {
			subscriptionPlanMutation.mutate(subscriptionPlan, {
				onSuccess() {
					refetch();
				},
			});
		}
	};

	return (
		<div className="SubscriptionPlan__wrapper">
			<Typography
				component="h1"
				variant="h5"
				style={{ padding: '20px 20px 5px 20px' }}
			>
				Subscription Plans{' '}
				<Button
					variant="contained"
					onClick={() => navigate('/create-subscription-plan')}
				>
					Create new
				</Button>
			</Typography>

			<ul>
				{data?.map((subscriptionPlan) => (
					<div aria-hidden="true" key={subscriptionPlan.id}>
						<span>{subscriptionPlan.name}</span>
						<Button onClick={() => openEditSubscriptionPlan(subscriptionPlan)}>
							Edit
						</Button>
						<Button onClick={() => removeSubscriptionPlan(subscriptionPlan)}>
							Delete
						</Button>
					</div>
				))}
			</ul>
		</div>
	);
};

export default SubscriptionPlans;
