import React, { FC, useState } from 'react';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import CodeEditorHTMLCSS from './CodeEditorHTMLCSS';
import InputTextField from '../../Form/InputTextField';
import { INPUT_TYPES } from '../../Form/formUtils';
import CKEditorField from '../CKEditor';
import CodeEditorHTML from './CodeEditorHTML';
import CodeEditorHTMLCSSJS from './CodeEditorHTMLCSSJS';
import CodeEditorJS from './CodeEditorJS';
import CodeEditorJsWithTests from './CodeEditorJsWithTests';
import CodeEditorHtmlScss from './CodeEditorHtmlScss';
// import CKEditorField from './CKEditor';

const CodeEditorOptions = {
	HTML: {
		label: 'HTML',
		value: 'html',
	},
	HTML_CSS: {
		label: 'HTML & CSS',
		value: 'html_css',
	},
	HTML_SCSS: {
		label: 'HTML & SCSS',
		value: 'html_scss',
	},
	HTML_CSS_JS: {
		label: 'HTML & CSS & JS',
		value: 'html_css_js',
	},
	JS: {
		label: 'JS',
		value: 'js',
	},
	JS_TESTS: {
		label: 'JS Task with tests',
		value: 'js_task_with_tests',
	},
};

type CodeEditorFieldProps = {
	field: FormField;
	formValues: any;
	validation: any;
	handleCodeEditorChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const CodeEditorField: FC<CodeEditorFieldProps> = ({
	field,
	formValues,
	validation,
	handleCodeEditorChange,
}) => {
	const isMetaContent = false;
	const isCodeTask = field.customType === 'codeTask';
	const [codeEditorType, setCodeEditorType] = useState<string>(
		isCodeTask
			? formValues[field.name]?.value || ''
			: formValues[field.name]?.value ||
					localStorage.getItem('lastUsedContentEditorType') ||
					'html',
	);
	const [title, setTitle] = useState<string>(
		formValues[field.name]?.options?.title || '',
	);
	const [description, setDescription] = useState<string>(
		formValues[field.name]?.options?.description || '',
	);
	const [iframeHeight, setIframeHeight] = useState<string>(
		formValues[field.name]?.options?.iframeHeight || null,
	);
	const [hideIcon, setHideIcon] = useState<boolean>(
		formValues[field.name]?.options?.hideTitleIcon || false,
	);
	const [isResizable, setIsResizable] = useState<boolean>(
		formValues[field.name]?.options?.isResizable || false,
	);
	const [leftColumnWidth, setLeftColumnWidth] = useState<string>(
		formValues[field.name]?.options?.leftColumnWidth || '',
	);
	const [jsOutputByDefault, setJsOutputByDefault] = useState<boolean>(
		formValues[field.name]?.options?.jsOutputByDefault || false,
	);

	const handleTypeChange = (event: SelectChangeEvent) => {
		setCodeEditorType(event.target.value as string);
		localStorage.setItem('lastUsedContentEditorType', event.target.value);
		const options = formValues[field.name]?.options || {};

		const updatedObject: any = {
			value: event.target.value,
		};
		if (isMetaContent) {
			updatedObject.options = {
				title: options.title || '',
				description: options.description || '',
			};
		}
		handleCodeEditorChange(updatedObject, field.name);
	};

	const handleCodeContentChange = (data: any) => {
		handleCodeEditorChange({ value: codeEditorType, options: data }, field.name);
	};

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
		const options = formValues[field.name]?.options || {};
		handleCodeEditorChange(
			{
				value: codeEditorType,
				options: {
					...options,
					title: e.target.value,
				},
			},
			field.name,
		);
	};

	const handleIframeHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIframeHeight(e.target.value);
		const options = formValues[field.name]?.options || {};
		handleCodeEditorChange(
			{
				value: codeEditorType,
				options: {
					...options,
					iframeHeight: e.target.value,
				},
			},
			field.name,
		);
	};

	const handleLeftColumnWidthChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		setLeftColumnWidth(e.target.value);
		const options = formValues[field.name]?.options || {};
		handleCodeEditorChange(
			{
				value: codeEditorType,
				options: {
					...options,
					leftColumnWidth: e.target.value,
				},
			},
			field.name,
		);
	};

	const handleCheckboxesChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		setStateFn: (value: boolean) => void,
		optionKey: string,
	) => {
		setStateFn(e.target.checked);
		const options = formValues[field.name]?.options || {};
		handleCodeEditorChange(
			{
				value: codeEditorType,
				options: {
					...options,
					[optionKey]: e.target.checked,
				},
			},
			field.name,
		);
	};

	const handleHideIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleCheckboxesChange(e, setHideIcon, 'hideTitleIcon');
	};
	const handleResizableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleCheckboxesChange(e, setIsResizable, 'isResizable');
	};
	const handleDefaultOutputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleCheckboxesChange(e, setJsOutputByDefault, 'jsOutputByDefault');
	};

	const handleDescriptionChange = (data: string) => {
		setDescription(data);
		const options = formValues[field.name]?.options || {};
		handleCodeEditorChange(
			{
				value: codeEditorType,
				options: {
					...options,
					description: data,
				},
			},
			field.name,
		);
	};

	return (
		<div style={{ color: validation?.[field.name] ? 'red' : 'inherit' }}>
			<Typography component="h5" sx={{ marginBottom: '15px' }}>
				{field.label}
			</Typography>
			<div style={{ display: 'flex', alignItems: 'baseline' }}>
				<FormControl fullWidth style={{ width: '23%', marginRight: '2%' }}>
					<InputLabel id="demo-simple-select-label">Type</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={codeEditorType}
						label="Type"
						onChange={handleTypeChange}
					>
						{Object.values(CodeEditorOptions).map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<InputTextField
					style={{ width: '18%', marginRight: '2%' }}
					onChange={handleIframeHeightChange}
					variant="outlined"
					fullWidth
					label="Iframe height (px)"
					name={field.name}
					type={INPUT_TYPES.NUMBER}
					defaultValue={iframeHeight}
					fieldInputType={field.inputType}
					sx={{ marginTop: '15px' }}
				/>
				<InputTextField
					style={{ width: '18%', marginRight: '2%' }}
					onChange={handleLeftColumnWidthChange}
					variant="outlined"
					fullWidth
					label="Left column width (%)"
					name={field.name}
					type={INPUT_TYPES.NUMBER}
					defaultValue={leftColumnWidth}
					fieldInputType={field.inputType}
					sx={{ marginTop: '15px' }}
				/>
				<FormGroup style={{ width: '12%' }}>
					<FormControlLabel
						control={
							<Checkbox
								// {...field}
								name={field.name}
								checked={hideIcon}
								onChange={handleHideIconChange}
							/>
						}
						label="Hide title icon"
					/>
				</FormGroup>
				<FormGroup style={{ width: '10%' }}>
					<FormControlLabel
						control={
							<Checkbox
								// {...field}
								name={field.name}
								checked={isResizable}
								onChange={handleResizableChange}
							/>
						}
						label="Resizable"
					/>
				</FormGroup>
				<FormGroup style={{ width: '20%' }}>
					<FormControlLabel
						control={
							<Checkbox
								// {...field}
								name={field.name}
								checked={jsOutputByDefault}
								onChange={handleDefaultOutputChange}
							/>
						}
						label="JS Output by default"
					/>
				</FormGroup>
			</div>

			{!isCodeTask && (
				<InputTextField
					onChange={handleTitleChange}
					variant="outlined"
					fullWidth
					label="Title"
					name={field.name}
					type={INPUT_TYPES.TEXT}
					defaultValue={title}
					fieldInputType={field.inputType}
					sx={{ marginTop: '15px' }}
				/>
			)}
			{!isCodeTask && (
				<div style={{ marginTop: '15px' }}>
					<CKEditorField
						{...field}
						label="Description"
						defaultValue={description}
						onChange={(data: string) => {
							handleDescriptionChange(data);
						}}
					/>
				</div>
			)}
			{codeEditorType === CodeEditorOptions.HTML_CSS.value && (
				<CodeEditorHTMLCSS
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
			{codeEditorType === CodeEditorOptions.HTML_SCSS.value && (
				<CodeEditorHtmlScss
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
			{codeEditorType === CodeEditorOptions.HTML.value && (
				<CodeEditorHTML
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
			{codeEditorType === CodeEditorOptions.HTML_CSS_JS.value && (
				<CodeEditorHTMLCSSJS
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
			{codeEditorType === CodeEditorOptions.JS.value && (
				<CodeEditorJS
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
			{codeEditorType === CodeEditorOptions.JS_TESTS.value && (
				<CodeEditorJsWithTests
					field={field}
					formValues={formValues}
					handleCodeContentChange={handleCodeContentChange}
				/>
			)}
		</div>
	);
};

export default CodeEditorField;
