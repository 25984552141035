import { FC, useState } from 'react';
import {
	TableRow,
	TableCell,
	IconButton,
	Collapse,
	Box,
	Typography,
} from '@mui/material';
import Moment from 'react-moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IOrder } from '../../lib/interfaces/Order';
import OrderStatusChange from './OrderStatusChange';

type RowProps = {
	row: IOrder;
	refetch: () => void;
};

const Row: FC<RowProps> = ({ row, refetch }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.orderNumber}
				</TableCell>
				<TableCell align="left">
					<Moment format="DD.MM.YYYY">{row.orderDate}</Moment>
				</TableCell>
				<TableCell align="left">{row.unitPrice}</TableCell>
				<TableCell align="left">{row.status}</TableCell>
				<TableCell align="left">{row.product.name}</TableCell>
				<TableCell align="left">
					{row.orderDetails?.user?.firstName} {row.orderDetails?.user?.lastName}
				</TableCell>
			</TableRow>
			<TableRow style={{ backgroundColor: '#f4f4f4' }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Box sx={{ margin: 1 }}>
								<Typography variant="h6" gutterBottom component="div">
									<strong>Status</strong>
								</Typography>
								<OrderStatusChange order={row} refetch={refetch} />
							</Box>
							<Box sx={{ margin: 1 }}>
								<Typography variant="h6" gutterBottom component="div">
									<strong>Order Details</strong>
								</Typography>
								<div style={{ display: 'flex', width: '100%', padding: '10px' }}>
									{row.orderDetails?.user && (
										<div style={{ paddingRight: '20px' }}>
											<Typography variant="body1" gutterBottom component="div">
												<strong>User</strong>
											</Typography>
											<div>ID: {row.orderDetails?.user?.id}</div>
											<div>
												Name: {row.orderDetails?.user?.firstName}{' '}
												{row.orderDetails?.user?.lastName}
											</div>
											<div>Email: {row.orderDetails?.user?.email}</div>
											{row.orderDetails?.meetingLink && (
												<div>
													Meeting:{' '}
													<a
														target="_blank"
														rel="noreferrer"
														href={row.orderDetails?.meetingLink}
													>
														Calendly
													</a>
												</div>
											)}
										</div>
									)}
									{row.orderDetails?.user && (
										<div style={{ paddingRight: '20px' }}>
											<Typography variant="body1" gutterBottom component="div">
												<strong>Product</strong>
											</Typography>
											<div>Current price: {row.product?.price}</div>
											<div>Order price: {row.orderDetails?.product?.price}</div>
											<div>ID: {row.orderDetails?.product?.id}</div>
											<div>Product: {row.product?.name}</div>
										</div>
									)}
									{row.orderDetails?.orderFormValues && (
										<div style={{ paddingRight: '20px' }}>
											<Typography variant="body1" gutterBottom component="div">
												<strong>Order Values</strong>
											</Typography>
											<div>
												{Object.entries(row.orderDetails?.orderFormValues).map(
													([key, value]) => (
														<div key={key}>
															{value ? (
																<span>
																	{key}: <span>{value as string}</span>
																</span>
															) : (
																<span> </span>
															)}
														</div>
													),
												)}
											</div>
										</div>
									)}
								</div>
							</Box>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default Row;
