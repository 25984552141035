import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'notistack';
import Navigationbar from './components/Navigation/NavigationBar';
import Home from './components/Home/HomePage';
import Modals from './components/Modals/Modals';
import Courses from './components/Courses/Courses';
import CourseCreate from './components/Courses/CourseCreate';
import CourseEdit from './components/Courses/CourseEdit';
import TasksList from './components/Task/TasksList';
import TaskCreate from './components/Task/TaskCreate';
import TaskEdit from './components/Task/TaskEdit';
import Quizzes from './components/Quizzes/Quizzes';
import QuizCreate from './components/Quizzes/CreateQuiz';
import QuizEdit from './components/Quizzes/QuizEdit';
import CodeTasks from './components/CodeTasks/CodeTasks';
import CodeTaskCreate from './components/CodeTasks/CodeTaskCreate';
import CodeTaskEdit from './components/CodeTasks/CodeTaskEdit';
import Products from './components/Products/Products';
import ProductCreate from './components/Products/ProductCreate';
import ProductEdit from './components/Products/ProductEdit';
import SubscriptionPlans from './components/SubscriptionPlans/SubscriptionPlans';
import SubscriptionPlanCreate from './components/SubscriptionPlans/SubscriptionPlanCreate';
import SubscriptionPlanEdit from './components/SubscriptionPlans/SubscriptionPlanEdit';
import OrdersManagement from './components/Orders/OrdersManagement';
import VideoItems from './components/VideoItems/VideoItems';
import VideoItemCreate from './components/VideoItems/VideoItemCreate';
import VideoItemEdit from './components/VideoItems/VideoItemEdit';
import MetadataLayer from './components/Metadata/MetadataLayer';
import CourseOccupations from './components/CourseOccupation/CourseOccupations';
import CourseOccupationCreate from './components/CourseOccupation/CourseOccupationCreate';
import CourseOccupationEdit from './components/CourseOccupation/CourseOccupationEdit';

import './App.css';
import SimpleBottomNavigation from './components/Navigation/BottomNvigation';
import Legals from './components/Legal/Legals';
import LegalCreate from './components/Legal/LegalCreate';
import LegalEdit from './components/Legal/LegalEdit';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
});

const App: FC = () => (
	<div className="App">
		<QueryClientProvider client={queryClient}>
			<SnackbarProvider maxSnack={3} autoHideDuration={1500}>
				<BrowserRouter>
					<Navigationbar />
					<Modals />
					<MetadataLayer />
					<div style={{ display: 'flex' }}>
						<div className="App__content">
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/courses" element={<Courses />} />
								<Route path="/create-course" element={<CourseCreate />} />
								<Route path="/edit-course/:slug" element={<CourseEdit />} />
								<Route path="/course/:slug/tasks" element={<TasksList />} />
								<Route path="/course/:slug/create-task/" element={<TaskCreate />} />
								<Route path="/edit-task/:slug" element={<TaskEdit />} />
								<Route path="/quizzes" element={<Quizzes />} />
								<Route path="/create-quiz" element={<QuizCreate />} />
								<Route path="/edit-quiz/:slug" element={<QuizEdit />} />
								<Route path="/code-tasks" element={<CodeTasks />} />
								<Route path="/create-code-task" element={<CodeTaskCreate />} />
								<Route path="/edit-code-task/:slug" element={<CodeTaskEdit />} />
								<Route path="/products" element={<Products />} />
								<Route path="/create-product" element={<ProductCreate />} />
								<Route path="/edit-product/:slug" element={<ProductEdit />} />
								<Route path="/subscription-plans" element={<SubscriptionPlans />} />
								<Route
									path="/create-subscription-plan"
									element={<SubscriptionPlanCreate />}
								/>
								<Route
									path="/edit-subscription-plan/:slug"
									element={<SubscriptionPlanEdit />}
								/>
								<Route path="/orders" element={<OrdersManagement />} />
								<Route path="/video-items" element={<VideoItems />} />
								<Route path="/create-video-item" element={<VideoItemCreate />} />
								<Route path="/edit-video-item/:id" element={<VideoItemEdit />} />
								<Route path="/course-occupation" element={<CourseOccupations />} />
								<Route
									path="/create-course-occupation"
									element={<CourseOccupationCreate />}
								/>
								<Route
									path="/edit-course-occupation/:id"
									element={<CourseOccupationEdit />}
								/>
								<Route path="/legals" element={<Legals />} />
								<Route path="/create-legal" element={<LegalCreate />} />
								<Route path="/edit-legal/:slug" element={<LegalEdit />} />

								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						</div>
						<SimpleBottomNavigation />
					</div>
				</BrowserRouter>
				<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			</SnackbarProvider>
		</QueryClientProvider>
	</div>
);

export default App;
