import { ILegal } from '../../lib/interfaces/Legal';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';
import { deleteLegal, getPaginatedLegals } from '../../api/apiRequests/legal';

const Legals = () => {
	const renderListItem = (legal: ILegal) => <span>{legal.name}</span>;
	const getId = (legal: ILegal) => legal.id;
	const getSlug = (legal: ILegal) => legal.slug;

	return (
		<EntityItemsPaginatedList<ILegal>
			entityName="Legal docs"
			getPaginatedEntityFn={getPaginatedLegals}
			deleteEntityFn={deleteLegal}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-legal/"
			navigateCreatePath="/create-legal/"
			defaultPaginationSettings={{
				page: 1,
				limit: 20,
			}}
			disableRemove
		/>
	);
};

export default Legals;
