import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	InputLabel,
	FormControl,
	MenuItem,
	TextField,
	Button,
	Box,
	Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FIELD_TYPES, supportedLaguages } from '../formUtils';
import { FormField } from '../../../lib/interfaces/Form';
import SimpleModal from '../../Modals/SimpleModal';
import {
	availableContentFields,
	fastAccessContentFields,
} from './availableContentFields';
import { CONTENT_ENTITIES } from '../../../lib/helpers/consts';

type CreateAdditionalFieldProps = {
	existingFields: FormField[];
	onSave: (value: any) => void;
	onCancel: () => void;
	contentEntity: CONTENT_ENTITIES;
};

const CreateAdditionalField: FC<CreateAdditionalFieldProps> = ({
	existingFields,
	onSave,
	onCancel,
	contentEntity,
}) => {
	const { t } = useTranslation();
	const availableListField = Object.values(FIELD_TYPES).filter(
		(field) =>
			availableContentFields[field] &&
			availableContentFields[field].availableForEntity?.includes(contentEntity),
	);
	const fastAccessFields = availableListField.filter((field) =>
		fastAccessContentFields.includes(field),
	);
	const defaultField = FIELD_TYPES.CODE_EDITOR;
	const defaultFieldName = t(`field_${FIELD_TYPES.CODE_EDITOR}`);
	const [selectedField, setSelectedField] = useState<string>(defaultField);
	const [fieldName, setFieldName] = useState<string>(defaultFieldName);
	const [invalidNameField, setInvalidNameField] = useState<boolean>(false);
	// const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

	const getUniqueFieldName = (name: string) => {
		let nameCandidate = name;

		// eslint-disable-next-line no-plusplus
		for (let n = 0; n < 20; n++) {
			let isUnique = true;
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < existingFields.length; i++) {
				let currentFieldName = existingFields[i].name;
				// eslint-disable-next-line no-plusplus
				for (let j = 0; j < supportedLaguages.length; j++) {
					const langSuffix = `_${supportedLaguages[j]}`;
					if (currentFieldName.includes(langSuffix)) {
						// eslint-disable-next-line prefer-destructuring
						currentFieldName = currentFieldName.split(langSuffix)[0];
						break;
					}
				}
				if (currentFieldName === nameCandidate) {
					isUnique = false;
					break;
				}
			}
			if (isUnique) {
				break;
			} else {
				nameCandidate = `${nameCandidate}${n + 1}`;
			}
		}
		return nameCandidate;
	};

	const createFieldNameProperty = (name?: string) => {
		const nameCandidate = (name || fieldName).replace(/\s/g, '').toLowerCase();
		return getUniqueFieldName(nameCandidate);
	};

	const handleSubmit = () => {
		const additionalField: FormField = {
			...availableContentFields[selectedField],
		};
		additionalField.label = fieldName;
		const name = createFieldNameProperty();
		additionalField.name = name;
		additionalField.custom = true;
		onSave(additionalField);
	};

	const handleFastAccess = (field: string) => {
		const additionalField: FormField = {
			...availableContentFields[field],
		};
		const fastFieldName = t(`field_${field}`);
		additionalField.label = fastFieldName;
		const name = createFieldNameProperty(field);
		additionalField.name = name;
		additionalField.custom = true;
		onSave(additionalField);
	};
	// const generateDefaultName = (fieldTypeString: string) => {
	// 	const randomString = (Math.random() + 1)
	// 		.toString(36)
	// 		.substring(2)
	// 		.replace(/[0-9]/g, '');
	// 	return getUniqueFieldName(`${fieldTypeString} ${randomString}`);
	// };
	const content: React.ReactElement = (
		<div style={{ padding: '20px', minWidth: '400px' }}>
			<form>
				<Box style={{ marginBottom: '20px' }}>
					<Typography variant="h6" component="h5">
						Add new content field
					</Typography>
					<Typography variant="body2" component="p">
						The field will be added to all supported langugages and will be not
						required to fill.
					</Typography>
				</Box>
				<div style={{ marginBottom: '20px' }}>
					<Typography variant="body2" component="p">
						Fast access:
					</Typography>
					{fastAccessFields.map((field) => (
						<Button
							key={field}
							variant="outlined"
							style={{ marginRight: '10px' }}
							onClick={() => handleFastAccess(field)}
						>
							{t(`field_${field}`)}
						</Button>
					))}
				</div>
				<FormControl fullWidth style={{ marginBottom: '10px' }}>
					<InputLabel id="demo-simple-select-label">Field type</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedField}
						label="Field type"
						onChange={(event: SelectChangeEvent) => {
							setSelectedField(event.target.value);
							const name = t(`field_${event.target.value}`).replace(/\//g, '');
							setFieldName(name);
						}}
					>
						{availableListField.map((field) => (
							<MenuItem key={field} value={field}>
								{t(`field_${field}`)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<div style={{ height: 0, visibility: 'hidden' }}>
					<FormControl fullWidth style={{ marginBottom: '10px' }}>
						<TextField
							id="outlined-basic"
							label="Field name"
							variant="outlined"
							value={fieldName}
							onChange={(event) => {
								const RegExpression = /^[a-zA-Z\s]*$/;
								setInvalidNameField(!RegExpression.test(event.target.value));
							}}
						/>
						{invalidNameField && (
							<div style={{ color: 'red', fontSize: '11px' }}>
								Name must content only latin letters
							</div>
						)}
					</FormControl>
				</div>
				<div>
					<Button
						variant="contained"
						disableElevation
						onClick={handleSubmit}
						// disabled={disabledSubmit}
					>
						Add
					</Button>
				</div>
			</form>
		</div>
	);

	return (
		<SimpleModal
			isOpen
			handleClose={onCancel}
			handleSave={handleSubmit}
			content={content}
			hideActions
		/>
	);
};

export default CreateAdditionalField;
