import { FormField } from '../../lib/interfaces/Form';
import { FIELD_TYPES } from '../Form/formUtils';
import { LegalType } from '../../lib/interfaces/Legal';

const LEGAL_IDENTITIES = [
	{
		label: 'Cookies message',
		value: LegalType.COOKIES_MESSAGE,
	},
	{
		label: 'Privacy policy',
		value: LegalType.PRIVACY_POLICY,
	},
	{
		label: 'Terms and conditions',
		value: LegalType.TERMS_AND_CONDITIONS,
	},
	{
		label: 'Cookie policy',
		value: LegalType.COOKIES_POLICY,
	},
];

export const legalFields: FormField[] = [
	{
		name: 'isPublished',
		init: false,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'identity',
		init: '',
		label: 'Identity',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: LEGAL_IDENTITIES,
	},
	{
		name: 'version',
		init: '',
		label: 'Version (auto calculated)',
		required: false,
		type: FIELD_TYPES.READ_ONLY,
	},
];

export const legalContentFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'description',
		init: '',
		label: 'Description',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
];

// export const getCourseFields = (metadata: IMetadata, data?: Course) => {
// 	const fields = cloneDeep(courseFields);
// 	fields.forEach((field) => {
// 		if (field.name === 'occupationIds') {
// 			field = generateOptions({
// 				selectedIds: data?.occupationIds,
// 				optionsList: metadata?.courseOccupations,
// 				labelKey: 'name',
// 				idKey: 'id',
// 				field,
// 			});
// 		}
// 		if (field.name === 'tags') {
// 			field = generateOptions({
// 				selectedIds: data?.tags,
// 				field,
// 			});
// 		}
// 		if (field.name === 'category') {
// 			field = generateOptions({
// 				selectedIds: data?.category,
// 				field,
// 			});
// 		}
// 		if (field.name === 'customClass' && data) {
// 			field = generateOptions({
// 				selectedIds: data.customClass,
// 				optionsList: field.defaultOptions,
// 				field,
// 			});
// 		}
// 	});
// 	return fields;
// };
