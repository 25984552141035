import { TPaginationSettings } from '../lib/interfaces/Pagination';
import { RefsFilter } from '../lib/interfaces/RefTypes';

const createPaginationParams = (pagination: TPaginationSettings | null) => {
	if (pagination) {
		const { page, limit } = pagination;
		return `page=${page}&limit=${limit}`;
	}
	return '';
};

const serialize = (filter: RefsFilter | null) => {
	if (!filter) {
		return '';
	}
	const resultQuery: any[] = [];
	Object.entries(filter).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach((arrayValue) => {
				const queryItem = `${key}[]=${arrayValue}`;
				resultQuery.push(queryItem);
			});
		} else {
			const queryItem = `${key}=${value}`;
			resultQuery.push(queryItem);
		}
	});
	return resultQuery.join('&');
};

export const createRefsPaginatedUrl = (
	baseUrl: string,
	pagination: TPaginationSettings | null | undefined,
	filter: RefsFilter | null | undefined,
) => {
	let url = baseUrl;
	if (pagination) {
		url = `${url}?${createPaginationParams(pagination)}`;
	}
	if (filter) {
		const nextSymbal = pagination ? '&' : '?';
		url = `${url}${nextSymbal}${serialize(filter)}`;
	}
	return url;
};
