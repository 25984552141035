import React, { FC, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormField } from '../../lib/interfaces/Form';
import { CUSTOM_FIELD_MENU_OPTION } from './formUtils';

type CustomFieldMenuProps = {
	handleCustomFieldAction: (data: any) => void;
	field: FormField;
	fieldIndex: number;
	fieldsLength: number;
};

const CustomFieldMenu: FC<CustomFieldMenuProps> = ({
	handleCustomFieldAction,
	field,
	fieldIndex,
	fieldsLength,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onMenuOptionSelect = (option: string) => {
		handleClose();
		handleCustomFieldAction({ option, field });
	};

	return (
		<div>
			<IconButton
				id="demo-positioned-button"
				aria-controls={open ? 'demo-positioned-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuItem
					onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.DELETE)}
				>
					Delete
				</MenuItem>
				{fieldIndex >= 2 && (
					<MenuItem
						onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.MOVE_UP)}
					>
						Move up
					</MenuItem>
				)}
				{fieldIndex < fieldsLength - 1 && (
					<MenuItem
						onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.MOVE_DOWN)}
					>
						Move down
					</MenuItem>
				)}
				<MenuItem
					onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.ADD_BEFORE)}
				>
					Add before
				</MenuItem>
				<MenuItem
					onClick={() => onMenuOptionSelect(CUSTOM_FIELD_MENU_OPTION.ADD_AFTER)}
				>
					Add after
				</MenuItem>
			</Menu>
		</div>
	);
};

export default CustomFieldMenu;
