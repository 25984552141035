import React, { FC, useState } from 'react';
import { FormField } from '../../../lib/interfaces/Form';
import MonacoCodeEditorField from './MonacoCodeEditor';

const DEFAULT_CONTENT = {
	HTML: '',
	SCSS: '',
};

type CodeEditorHtmlScssProps = {
	field: FormField;
	formValues: any;
	handleCodeContentChange: (data: any) => void;
};

const CodeEditorHtmlScss: FC<CodeEditorHtmlScssProps> = ({
	field,
	formValues,
	handleCodeContentChange,
}) => {
	const isMetaContent = field.metaContent;
	const optionsPath = isMetaContent
		? formValues[field.name]?.value?.options
		: formValues[field.name]?.options;
	const [htmlContent, setHtmlContent] = useState<string>(
		optionsPath?.html || DEFAULT_CONTENT.HTML,
	);
	const [cssContent, setCssContent] = useState<string>(
		optionsPath?.scss || DEFAULT_CONTENT.SCSS,
	);

	const handleHTMLEditorChange = (data: string) => {
		setHtmlContent(data);
		handleCodeContentChange({ ...formValues[field.name]?.options, html: data });
	};

	const handleCSSEditorChange = (data: string) => {
		setCssContent(data);
		handleCodeContentChange({ ...formValues[field.name]?.options, scss: data });
	};

	return (
		<div>
			<div style={{ display: 'flex', paddingTop: '15px' }}>
				<div style={{ width: '50%' }}>
					<div style={{ padding: '5px' }}>HTML</div>
					<MonacoCodeEditorField
						langauge="html"
						height="150px"
						defaultValue={htmlContent}
						onChange={(data: string) => {
							handleHTMLEditorChange(data);
						}}
					/>
				</div>
				<div style={{ width: '50%' }}>
					<div style={{ padding: '5px' }}>SCSS</div>
					<MonacoCodeEditorField
						langauge="scss"
						height="150px"
						defaultValue={cssContent}
						onChange={(data: string) => {
							handleCSSEditorChange(data);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CodeEditorHtmlScss;
