import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createTask } from '../../api/apis';
import { Task } from '../../lib/interfaces/Task';
import { Course } from '../../lib/interfaces/Course';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { getTaskFormFields } from './taskFormFields';
import { getAllSubscriptionPlans } from '../../api/apiRequests/subscriptionPlans';
import { ISubscriptionPlan } from '../../lib/interfaces/SubscriptionPlan';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { getCourseBySlug } from '../../api/apiRequests/course';

const TaskCreate = () => {
	const navigate = useNavigate();
	const { slug = '' } = useParams();
	const queryClient = useQueryClient();

	const { data: dataCourse } = useQuery<Course, Error>([`course-${slug}`], () =>
		getCourseBySlug(slug),
	);

	const { data: subscriptionPlans } = useQuery<ISubscriptionPlan[], Error>(
		['subscriptionPlans'],
		() => getAllSubscriptionPlans(),
	);

	const createTaskMutation = useMutation<Task, Error, Task>((formValues) =>
		createTask(formValues),
	);

	const { isLoading, isSuccess, error } = createTaskMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries([`course-${slug}`]);
			navigate(-1); // TODO navigate to single course page
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		const data: Task = {
			...formValues,
			courseId: dataCourse?.id,
		};
		createTaskMutation.mutate(data);
	};

	return subscriptionPlans ? (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={null}
			error={error}
			saveButtonName="Create"
			entityName="Task"
			metaFields={getTaskFormFields(subscriptionPlans)}
			contentEntity={CONTENT_ENTITIES.TASK}
		/>
	) : null;
};

export default TaskCreate;
