import React, { FC, useEffect, useState } from 'react';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextareaAutosize,
	TextField,
	SelectChangeEvent,
	Button,
} from '@mui/material';
import { useMutation } from 'react-query';
import { ProductStatus } from '../../lib/helpers/consts';
import { IOrder, TOrderStatusChangeDTO } from '../../lib/interfaces/Order';
import OrderStatusChangeLog from './OrderStatusChangeLog';
import { changeOrderStatus } from '../../api/apiRequests/orders';

type OrderStatusChangeProps = {
	order: IOrder;
	refetch: () => void;
};

const OrderStatusChange: FC<OrderStatusChangeProps> = ({ order, refetch }) => {
	const [currentStatus, setCurrentStatus] = useState<ProductStatus>();
	const [note, setNote] = useState<string>('');

	useEffect(() => {
		setCurrentStatus(order.status);
	}, [order.status]);

	const handleStatusChange = (event: SelectChangeEvent<ProductStatus>) => {
		const {
			target: { value },
		} = event;
		setCurrentStatus(value as ProductStatus);
	};

	const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNote(e.target.value);
	};

	const statusChangeMutation = useMutation<IOrder, Error, TOrderStatusChangeDTO>(
		(data: TOrderStatusChangeDTO) => changeOrderStatus(data, order.id),
		{
			onSuccess: () => {
				setCurrentStatus(undefined);
				setNote('');
				refetch();
			},
		},
	);

	const handleStatusChangeRequest = () => {
		if (currentStatus) {
			statusChangeMutation.mutate({
				status: currentStatus,
				note,
			});
		}
	};

	return (
		<>
			<div style={{ display: 'flex' }}>
				<div style={{ paddingRight: '10px', minWidth: '200px' }}>
					{currentStatus && (
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Status</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={currentStatus}
								label="Status"
								onChange={handleStatusChange}
							>
								{Object.values(ProductStatus).map((value) => (
									<MenuItem value={value} key={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</div>
				<TextField
					style={{ marginRight: '10px' }}
					onChange={handleCommentChange}
					variant="outlined"
					fullWidth
					label="Note"
					value={note}
					InputProps={{
						inputComponent: TextareaAutosize,
						rows: 3,
					}}
				/>
				<Button variant="contained" onClick={handleStatusChangeRequest}>
					Change
				</Button>
			</div>
			{order.statusChangeLog && (
				<div style={{ marginTop: '20px' }}>
					<OrderStatusChangeLog order={order} />
				</div>
			)}
		</>
	);
};

export default OrderStatusChange;
