import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
	getVideoItemById,
	updateVideoItem,
} from '../../api/apiRequests/videoItems';
import { useAppDispatch } from '../../hooks/redux';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { IVideoItem } from '../../lib/interfaces/VideoItem';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { videoItemFields } from './VideoItemsFormFields';

const VideoItemEdit = () => {
	const { id = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const dispatch = useAppDispatch();

	const singleVideoItem = useQuery<IVideoItem, Error>([`VideoItem-${id}`], () =>
		getVideoItemById(Number(id)),
	);
	const { data, isLoading, error, refetch } = singleVideoItem;

	const updateVideoItemMutation = useMutation<IVideoItem, Error, IVideoItem>(
		(formValues: IVideoItem) => updateVideoItem(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateVideoItemMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Video has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	useEffect(() => {
		dispatch(
			setRecent({ entity: RecentEntities.VIDEO, link: `edit-video-item/${id}` }),
		);
	}, [data]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateVideoItemMutation.mutate(formValues);
		}
	};

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={videoItemFields}
			entityName="Video"
			contentEntity={CONTENT_ENTITIES.VIDEO_ITEM}
		/>
	) : null;
};

export default VideoItemEdit;
