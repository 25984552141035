import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { createProduct } from '../../api/apis';
import { initialContentForProduct } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { IProduct } from '../../lib/interfaces/Product';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import useGetMetadataQuery from '../../hooks/useGetMetadata';
import { getProductFields } from './productFields';

const ProductCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { data: metadata } = useGetMetadataQuery();

	const createProductMutation = useMutation<IProduct, Error, IProduct>(
		(formValues) => createProduct(formValues),
	);
	const { isLoading, isSuccess, error } = createProductMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['products']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createProductMutation.mutate(formValues);
	};

	const initialProductContent = cloneDeep(initialContentForProduct);
	const productInit: Partial<IProduct> = {
		content: initialProductContent,
	};

	return metadata ? (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={productInit}
			error={error}
			saveButtonName="Create"
			metaFields={getProductFields(metadata)}
			entityName="Product"
			contentEntity={CONTENT_ENTITIES.PRODUCT}
		/>
	) : (
		<span>Loading...</span>
	);
};

export default ProductCreate;
