import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { ICourseOccupation } from '../../lib/interfaces/CourseOccupation';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const apiBase = 'course-occupation-dashboard';

const endpoints = {
	getCourseOccupationById: (id: number) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/${apiBase}/${id}`,
		method: API_METHODS.GET,
	}),

	getAllCourseOccupations: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/${apiBase}`,
		method: API_METHODS.GET,
	}),

	getPaginatedCourseOccupations: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/${apiBase}`,
			pagination,
			filter,
		);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createCourseOccupation: (data: ICourseOccupation) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/${apiBase}`,
		headers: createHeader(null, true),
		data,
	}),

	updateCourseOccupation: (
		data: Partial<ICourseOccupation>,
		id: number | undefined,
	) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/${apiBase}/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteCourseOccupation: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/${apiBase}/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getCourseOccupationById = async (id: number) =>
	axiosRequest(endpoints.getCourseOccupationById(id));

export const getAllCourseOccupations = async () =>
	axiosRequest(endpoints.getAllCourseOccupations());

export const getPaginatedCourseOccupations = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedCourseOccupations(filter, pagination));

export const createCourseOccupation = async (data: ICourseOccupation) =>
	axiosRequest(endpoints.createCourseOccupation(data));

export const updateCourseOccupation = async (
	data: Partial<ICourseOccupation>,
	id: number | undefined,
) => axiosRequest(endpoints.updateCourseOccupation(data, id));

export const deleteCourseOccupation = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteCourseOccupation(id));
