import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getQuizBySlug, updateQuiz } from '../../api/apiRequests/quiz';
import { useAppDispatch } from '../../hooks/redux';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { IQuiz } from '../../lib/interfaces/Quiz';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import { quizFields } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';

const QuizEdit = () => {
	const { slug = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const dispatch = useAppDispatch();

	const singleQuiz = useQuery<IQuiz, Error>([`quiz-${slug}`], () =>
		getQuizBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleQuiz;

	const updateQuizMutation = useMutation<IQuiz, Error, IQuiz>(
		(formValues: IQuiz) => updateQuiz(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateQuizMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Quiz has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	useEffect(() => {
		dispatch(
			setRecent({ entity: RecentEntities.QUIZ, link: `edit-quiz/${slug}` }),
		);
	}, [data]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateQuizMutation.mutate(formValues);
		}
	};

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={quizFields}
			entityName="Quiz"
			contentEntity={CONTENT_ENTITIES.QUIZ}
		/>
	) : null;
};

export default QuizEdit;
