import { Task } from '../lib/interfaces/Task';
import { UserDTO } from '../lib/interfaces/User';
import { endpoints } from './endpointBuilder';
import { axiosRequest } from './request';
import { IProductDTO } from '../lib/interfaces/Product';

// TODO: move everything to separate files

export const login = async (data: UserDTO) =>
	axiosRequest(endpoints.auth.getLoginApi(data));

// TASK

export const getTaskBySlug = async (slug: string) =>
	axiosRequest(endpoints.task.getTaskById(slug));

export const createTask = async (data: Task) =>
	axiosRequest(endpoints.task.createTask(data));

export const updateTask = async (data: Task, id: number | undefined) =>
	axiosRequest(endpoints.task.updateTask(data, id));

export const deleteTask = async (id: number | undefined) =>
	axiosRequest(endpoints.task.deleteTask(id));

// PRODUCTS

export const getProductBySlug = async (slug: string) =>
	axiosRequest(endpoints.product.getProductBySlug(slug));

export const getAllProducts = async () =>
	axiosRequest(endpoints.product.getAllProducts());

export const createProduct = async (data: IProductDTO) =>
	axiosRequest(endpoints.product.createProduct(data));

export const updateProduct = async (
	data: Partial<IProductDTO>,
	id: number | undefined,
) => axiosRequest(endpoints.product.updateProduct(data, id));

export const deleteProduct = async (id: number | undefined) =>
	axiosRequest(endpoints.product.deleteProduct(id));

// FILE UPLOAD

export const uploadFile = async (data: FormData) =>
	axiosRequest(endpoints.file.uploadFile(data));

export const uploadVideo = async (data: FormData) =>
	axiosRequest(endpoints.file.uploadVideo(data));
