import { useSnackbar } from 'notistack';

export enum SnackbarTypes {
	SUCCESS = 'success',
	ERROR = 'error',
}

const useEnqueuedSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar();
	const callSnackBar = (text: string, type: SnackbarTypes) =>
		enqueueSnackbar(text, {
			variant: type,
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'right',
			},
			autoHideDuration: type === SnackbarTypes.SUCCESS ? 1500 : 2500,
		});
	const useSuccessSnack = (text: string) =>
		callSnackBar(text, SnackbarTypes.SUCCESS);

	const useErrorSnack = (text: string) =>
		callSnackBar(text, SnackbarTypes.ERROR);

	return { useSuccessSnack, useErrorSnack };
};

export default useEnqueuedSnackbar;
