import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import PropTypes from 'prop-types';
import { uploadFile } from '../../api/apis';

const CKEditorField = ({ label, defaultValue = '', onChange }) => {
	function uploadAdapter(loader) {
		return {
			upload: () =>
				new Promise((resolve, reject) => {
					const body = new FormData();
					loader.file.then((file) => {
						body.append('image', file);
						uploadFile(body).then(
							(res) => {
								resolve({ default: res.url });
							},
							(err) => {
								reject(err);
							},
						);
					});
				}),
		};
	}
	function uploadPlugin(editor) {
		// eslint-disable-next-line no-param-reassign
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
			uploadAdapter(loader);
		console.log(editor.plugins.get('CodeBlock').languages);
	}
	return (
		<div className="App">
			<div style={{ padding: '5px 0' }}>{label}</div>
			<CKEditor
				config={{
					extraPlugins: [uploadPlugin],
				}}
				editor={Editor}
				data={defaultValue}
				// onReady={(editor) => {}}
				// onChange={(event, editor) => {}}
				onBlur={(event, editor) => {
					const data = editor.getData();
					onChange(data);
				}}
				// onFocus={(event, editor) => {}}
			/>
		</div>
	);
};

CKEditorField.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default CKEditorField;
