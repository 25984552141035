/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import Editor from '@monaco-editor/react';
// import './CodeEditorHtmlCss.scss';
// import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

// const createWorker = createWorkerFactory(() =>
// import('./CodeEvaluator.worker.js'),
// );

const MonacoCodeEditorField = ({
	langauge,
	defaultValue,
	onChange,
	height,
}) => {
	const editorRef = useRef(null);

	const handleEditorDidMount = (editor) => {
		editor.updateOptions({ fontSize: '14px' });
		editorRef.current = editor;
	};

	const handleEditorValidation = (markers) => {
		markers.forEach((marker) => console.log('onValidate:', marker.message));
	};

	return (
		<Editor
			height={height || '300px'}
			theme="vs-dark"
			defaultLanguage={langauge}
			defaultValue={defaultValue}
			onValidate={handleEditorValidation}
			onMount={handleEditorDidMount}
			onChange={onChange}
			// path={files.html.name}
		/>
	);
};

export default MonacoCodeEditorField;
