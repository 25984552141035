import React, { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTaskBySlug, updateTask } from '../../api/apis';
import { Task } from '../../lib/interfaces/Task';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { getAllSubscriptionPlans } from '../../api/apiRequests/subscriptionPlans';
import { ISubscriptionPlan } from '../../lib/interfaces/SubscriptionPlan';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import { useAppDispatch } from '../../hooks/redux';
import { getTaskFormFields } from './taskFormFields';
// import TaskSingle from './TaskSingle';

const TaskEdit = () => {
	const { slug = '' } = useParams();
	const queryClient = useQueryClient();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const dispatch = useAppDispatch();

	const singleTask = useQuery<Task, Error>([`task-${slug}`], () =>
		getTaskBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleTask;

	const { data: subscriptionPlans } = useQuery<ISubscriptionPlan[], Error>(
		['subscriptionPlans'],
		() => getAllSubscriptionPlans(),
	);

	const updateTaskMutation = useMutation<Task, Error, Task>((formValues) =>
		updateTask(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateTaskMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			queryClient.invalidateQueries([`course-${slug}`]);
			useSuccessSnack('Task has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	useEffect(() => {
		dispatch(
			setRecent({ entity: RecentEntities.TASK, link: `edit-task/${slug}` }),
		);
	}, [data]);

	const onSubmit = (formValues: any) => updateTaskMutation.mutate(formValues);

	return data && subscriptionPlans ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			entityName="Task"
			metaFields={getTaskFormFields(subscriptionPlans, data)}
			contentEntity={CONTENT_ENTITIES.TASK}
		/>
	) : null;
};

export default TaskEdit;
