import { Course } from '../../lib/interfaces/Course';
import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { createHeader, BASE_URL, API_METHODS } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getCourseBySlug: (slug: string) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/course-dashboard/${slug}`,
		method: API_METHODS.GET,
	}),

	getPaginatedCourses: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/course-dashboard`,
			pagination,
			filter,
		);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createCourse: (data: Course) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/course-dashboard`,
		headers: createHeader(null, true),
		data,
	}),

	updateCourse: (data: Partial<Course>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/course-dashboard/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteCourse: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/course-dashboard/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getCourseBySlug = async (slug: string) =>
	axiosRequest(endpoints.getCourseBySlug(slug));

export const createCourse = async (data: Course) =>
	axiosRequest(endpoints.createCourse(data));

export const updateCourse = async (data: Partial<Course>, id: number | undefined) => {
	if (data.tasks) {
		delete data.tasks; // Safe update, prevent removing nested task objects
	}
	return axiosRequest(endpoints.updateCourse(data, id));
};

export const deleteCourse = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteCourse(id));

export const getPaginatedCourses = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedCourses(filter, pagination));
