import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getLegalBySlug, updateLegal } from '../../api/apiRequests/legal';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import useGetMetadataQuery from '../../hooks/useGetMetadata';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { ILegal, ILegalDTO } from '../../lib/interfaces/Legal';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { legalFields } from './legalFormFields';

const LegalEdit = () => {
	const { slug = '' } = useParams();
	const { data: metadata } = useGetMetadataQuery();
	const { useSuccessSnack } = useEnqueuedSnackbar();

	const singleLegal = useQuery<ILegal, Error>([`legal-${slug}`], () =>
		getLegalBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleLegal;

	const updateLegalMutation = useMutation<ILegalDTO, Error, ILegalDTO>(
		(formValues: ILegalDTO) => updateLegal(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateLegalMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Legal has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateLegalMutation.mutate(formValues);
		}
	};

	return data && metadata ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={legalFields}
			entityName="Legal"
			contentEntity={CONTENT_ENTITIES.COURSE}
		/>
	) : null;
};

export default LegalEdit;
