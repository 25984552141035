import {
	ContentFormPossibleValues,
	FormField,
	IEntityContent,
	IEntityContentField,
	IFormFields,
} from '../../lib/interfaces/Form';
import { supportedLaguages } from './formUtils';

export const createContentFields = (entityContentFields: FormField[]) => {
	const contentFields: FormField[] = [];
	supportedLaguages.forEach((lang) => {
		entityContentFields.forEach((field) => {
			contentFields.push({
				...field,
				name: `${field.name}_${lang}`,
				label: `${field.label}_${lang}`,
			});
		});
	});
	return contentFields;
};

export const parseValuesBeforeSubmit = (
	formValues: any,
	contentFields: FormField[],
) => {
	const data: any = {};
	const content: any = {};
	supportedLaguages.forEach((lang) => {
		content[lang] = {};
	});
	const contentFieldsKeys = contentFields.map((field) => field.name);
	Object.entries(formValues).forEach((entry) => {
		const [key, value] = entry;
		if (contentFieldsKeys.includes(key)) {
			const [name, lang] = key.split('_');
			content[lang][name] = value;
		} else {
			data[key] = value;
		}
	});
	data.content = content;
	return data;
};

export const parseInitialValues = (initialValues: any) => {
	const formValues = { ...initialValues };
	delete formValues.content;
	if (initialValues) {
		try {
			const { content } = initialValues;
			if (content) {
				supportedLaguages.forEach((lang) => {
					Object.entries(content[lang]).forEach((entry) => {
						const [key, value] = entry;
						formValues[`${key}_${lang}`] = value;
					});
				});
			}
		} catch (e: any) {
			console.error(e);
		}
	}
	return formValues;
};

/* const getContentOptionsByFieldType = (field: IEntityContentField) => {
	if (field.type === FIELD_TYPES.HINT) {
		return {
			label: field.options?.label || '',
		};
	}
	return null;
}; */

export const convertInitialValues = (initialValues: any) => {
	const formValues = { ...initialValues };
	delete formValues.content;
	Object.entries(formValues).forEach((entry) => {
		const [key, value] = entry;
		formValues[key] = {
			value,
		};
	});
	if (initialValues) {
		try {
			const { content } = initialValues;
			if (content) {
				supportedLaguages.forEach((lang) => {
					content[lang].forEach((field: IEntityContentField) => {
						formValues[field.name] = {
							value: field.content,
							options: field.options,
						};
						if (field.reference) {
							formValues[field.name].reference = field.reference;
						}
					});
				});
			}
		} catch (e: any) {
			console.error(e);
		}
	}
	return formValues;
};

export const flattenFormFieldsWithContent = (formFields: IFormFields) => {
	let flattenedFields: FormField[] = [];
	flattenedFields = [...formFields.meta];
	supportedLaguages.forEach((lang) => {
		flattenedFields = [...flattenedFields, ...formFields.content[lang]];
	});
	return flattenedFields;
};

export const convertValuesBeforeSubmit = (
	formValues: any,
	formFields: IFormFields,
) => {
	const metaFields: any = {};
	formFields.meta.forEach((field: FormField) => {
		if (field.metaContent) {
			metaFields[field.name] = formValues[field.name];
		} else {
			metaFields[field.name] = formValues[field.name]?.value;
		}
	});
	const content: IEntityContent = {};
	supportedLaguages.forEach((lang) => {
		content[lang] = [];
		formFields.content[lang].forEach((field: FormField) => {
			const contentField: IEntityContentField = {
				name: field.name,
				type: field.type,
				label: field.label,
				content: formValues[field.name]?.value,
			};
			if (formValues[field.name]?.options) {
				contentField.options = formValues[field.name].options;
			}
			// if (formValues[field.name]?.reference) {
			// 	contentField.reference = formValues[field.name].reference;
			// }
			if (field.custom) {
				contentField.custom = true;
			}
			content[lang].push(contentField);
		});
	});
	return {
		...metaFields,
		content,
	};
};

// This function checks if there is a difference between initial content fields
// In case of difference it adds fields
// This use case appers when previously saved entity was modified
// before new initial field were added to show new fields for old entity in edit mode
export const checkAndAddInitialFields = (
	values: ContentFormPossibleValues,
	initialFields: IEntityContent,
) => {
	supportedLaguages.forEach((lang) => {
		initialFields[lang].forEach((field) => {
			const hasField = values?.content?.[lang]?.find(
				(contentField) => contentField.name === field.name,
			);
			if (!hasField) {
				values?.content?.[lang].push(field);
			}
		});
	});
	return values;
};

export const addCustomType = (
	values: ContentFormPossibleValues,
	initialFields: IEntityContentField[],
) => {
	initialFields.forEach((initalField) => {
		supportedLaguages.forEach((lang) => {
			const field = `${initalField.name}_${lang}`;
			const fieldIndex = values?.content?.[lang]?.findIndex(
				(contentField) => contentField.name === field,
			);
			if (fieldIndex && values?.content?.[lang][fieldIndex]) {
				values.content[lang][fieldIndex] = {
					...values?.content?.[lang][fieldIndex],
					customType: initalField.customType,
				};
			}
		});
	});
	return values;
};
