import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getCourseBySlug, updateCourse } from '../../api/apiRequests/course';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import useGetMetadataQuery from '../../hooks/useGetMetadata';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { Course } from '../../lib/interfaces/Course';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { getCourseFields } from './courseFormFields';

const CourseEdit = () => {
	const { slug = '' } = useParams();
	const { data: metadata } = useGetMetadataQuery();
	const { useSuccessSnack } = useEnqueuedSnackbar();

	const singleCourse = useQuery<Course, Error>([`course-${slug}`], () =>
		getCourseBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleCourse;

	const updateCourseMutation = useMutation<Course, Error, Course>(
		(formValues: Course) => updateCourse(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateCourseMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Course has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateCourseMutation.mutate(formValues);
		}
	};

	return data && metadata ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={getCourseFields(metadata, data)}
			entityName="Course"
			contentEntity={CONTENT_ENTITIES.COURSE}
		/>
	) : null;
};

export default CourseEdit;
