import { createSlice } from '@reduxjs/toolkit';
import { Course } from '../../../lib/interfaces/Course';

type InitialState = {
	courseToEdit: Course | null;
};

const initialState = {
	courseToEdit: null,
} as InitialState;

const courseReducer = createSlice({
	name: 'course',
	initialState,
	reducers: {
		setCourseToEdit: (state, action) => {
			state.courseToEdit = action.payload;
		},
	},
});

export const { setCourseToEdit } = courseReducer.actions;
export default courseReducer;
