import { Button, Paper, TablePagination, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getPaginatedCodeTasks } from '../../../api/apiRequests/codeTask';
import { getPaginatedCourses } from '../../../api/apiRequests/course';
import { getPaginatedQuizes } from '../../../api/apiRequests/quiz';
import { getPaginatedVideoItems } from '../../../api/apiRequests/videoItems';
import {
	IPagination,
	TPaginationSettings,
} from '../../../lib/interfaces/Pagination';
import { PossibleRefs, RefsFilter } from '../../../lib/interfaces/RefTypes';
import { RefType } from '../../Form/formUtils';
import SimpleModal from '../../Modals/SimpleModal';
import RefSelectorTable from './RefSelectorTable';

type RefSelectorProps = {
	refType: RefType;
	onSelect: (item: PossibleRefs) => void;
	onCancel: () => void;
	disableItemIds?: number[];
};

const RefSelector: FC<RefSelectorProps> = ({
	refType,
	onSelect,
	onCancel,
	disableItemIds,
}) => {
	const [pagination, setPagination] = useState<TPaginationSettings | undefined>(
		undefined,
	);
	const [filter, setFilter] = useState<RefsFilter | undefined>(undefined);
	const [filterName, setFilterName] = useState<string>('');
	const paginationKey = pagination
		? `${pagination.page}-${pagination.limit}`
		: '';
	const filterKey = filter ? `name-${filter.name}` : '';

	const fetchFn: {
		[key: string]: (
			filter?: RefsFilter,
			pagination?: TPaginationSettings,
		) => Promise<IPagination<PossibleRefs>>;
	} = {
		[RefType.VIDEO_REF]: getPaginatedVideoItems,
		[RefType.CODE_TASK_REF]: getPaginatedCodeTasks,
		[RefType.QUIZ_REF]: getPaginatedQuizes,
		[RefType.COURSE_REF]: getPaginatedCourses,
	};

	const { data, isLoading } = useQuery<IPagination<PossibleRefs>, Error>(
		[refType, paginationKey, filterKey, 'refs'],
		() => fetchFn[refType](filter, pagination),
	);

	const handleSubmit = () => {};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => {
		if (data) {
			setPagination({
				page: page + 1,
				limit: data.meta.itemsPerPage,
			});
		}
	};

	const handleNameSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFilterName(e.target.value);

	const handleFilterChangeRequest = () => {
		setFilter({
			name: filterName,
		});
	};

	const handleClearFilter = () => {
		setFilterName('');
		setFilter(undefined);
	};

	const handleRefSelect = (item: PossibleRefs) => {
		onSelect(item);
	};

	const content = (
		<Paper sx={{ minWidth: '500px' }} elevation={0}>
			<div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '10px' }}>
				<TextField
					style={{ marginRight: '10px' }}
					onChange={handleNameSearchChange}
					variant="outlined"
					fullWidth
					label="Name"
					value={filterName}
				/>
				<Button
					variant="contained"
					onClick={handleFilterChangeRequest}
					sx={{ marginRight: '5px' }}
				>
					Filter
				</Button>
				{filter?.name && (
					<Button variant="contained" onClick={handleClearFilter}>
						Clear
					</Button>
				)}
			</div>

			{data && (
				<RefSelectorTable
					data={data}
					onSelect={handleRefSelect}
					disableItemIds={disableItemIds}
				/>
			)}
			{data && (
				<TablePagination
					rowsPerPageOptions={[10]}
					component="div"
					count={data.meta.totalItems}
					rowsPerPage={data.meta.itemsPerPage}
					page={data.meta.currentPage - 1}
					onPageChange={handleChangePage}
				/>
			)}
			{isLoading && <div>Loading...</div>}
			{!isLoading && !data && <div>No data</div>}
		</Paper>
	);

	return (
		<SimpleModal
			isOpen
			handleClose={onCancel}
			handleSave={handleSubmit}
			content={content}
			hideActions
		/>
	);
};

export default RefSelector;
