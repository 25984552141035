import { ICodeTask } from '../../lib/interfaces/CodeTask';
import {
	deleteCodeTask,
	getPaginatedCodeTasks,
} from '../../api/apiRequests/codeTask';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';

const CodeTasks = () => {
	const renderListItem = (codeTask: ICodeTask) => <span>{codeTask.name}</span>;
	const getId = (codeTask: ICodeTask) => codeTask.id;
	const getSlug = (codeTask: ICodeTask) => codeTask.slug;

	return (
		<EntityItemsPaginatedList<ICodeTask>
			entityName="Code Task"
			getPaginatedEntityFn={getPaginatedCodeTasks}
			deleteEntityFn={deleteCodeTask}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-code-task/"
			navigateCreatePath="/create-code-task/"
		/>
	);
};

export default CodeTasks;
