import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
	getSubscriptionPlanBySlug,
	updateSubscriptionPlan,
} from '../../api/apiRequests/subscriptionPlans';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { ISubscriptionPlan } from '../../lib/interfaces/SubscriptionPlan';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { subscriptionPlanFields } from './SubscriptionPlansFormFields';

const SubscriptionPlanEdit = () => {
	const { slug = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();

	const singleSubscriptionPlan = useQuery<ISubscriptionPlan, Error>(
		[`subscriptionPlan-${slug}`],
		() => getSubscriptionPlanBySlug(slug),
	);
	const { data, isLoading, error, refetch } = singleSubscriptionPlan;

	const updateSubscriptionPlanMutation = useMutation<
		ISubscriptionPlan,
		Error,
		ISubscriptionPlan
	>((formValues: ISubscriptionPlan) =>
		updateSubscriptionPlan(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateSubscriptionPlanMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Code Task has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateSubscriptionPlanMutation.mutate(formValues);
		}
	};

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={subscriptionPlanFields}
			entityName="SubscriptionPlan"
			contentEntity={CONTENT_ENTITIES.SUBSCRIPTION_PLAN}
		/>
	) : null;
};

export default SubscriptionPlanEdit;
