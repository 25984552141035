import React, { FC, useState } from 'react';
import {
	IconButton,
	InputAdornment,
	StandardTextFieldProps,
	TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { INPUT_TYPES } from './formUtils';

interface InputTextFieldProps extends StandardTextFieldProps {
	fieldInputType?: string;
}

const InputTextField: FC<any> = ({
	fieldInputType,
	...props
}: InputTextFieldProps) => {
	const [showPassword, setShowPassword] = useState(false);

	const isPasswordField = fieldInputType === INPUT_TYPES.PASSWORD;

	return isPasswordField ? (
		<TextField
			{...props}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	) : (
		<TextField {...props} />
	);
};

export default InputTextField;
