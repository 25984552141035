import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { IQuiz } from '../../lib/interfaces/Quiz';
import {
	quizFields,
	initialContent,
	quizInitialContentField,
	supportedLaguages,
} from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { createQuiz } from '../../api/apiRequests/quiz';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

const QuizCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createQuizMutation = useMutation<IQuiz, Error, IQuiz>((formValues) =>
		createQuiz(formValues),
	);
	const { isLoading, isSuccess, error } = createQuizMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['quizzes']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => createQuizMutation.mutate(formValues);

	const initialQuizContent = cloneDeep(initialContent);
	supportedLaguages.forEach((lang) => {
		initialQuizContent[lang].push({
			...quizInitialContentField,
			name: `${quizInitialContentField.name}_${lang}`,
		});
	});
	const quizInit: Partial<IQuiz> = {
		content: initialQuizContent,
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={quizInit}
			// initialValuesForCreate={quizInit}
			error={error}
			saveButtonName="Create"
			metaFields={quizFields}
			entityName="Quiz"
			contentEntity={CONTENT_ENTITIES.QUIZ}
		/>
	);
};

export default QuizCreate;
