import { Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPaginatedCourses } from '../../../api/apiRequests/course';
import { OrderObject } from '../../../lib/helpers/consts';
import { Course } from '../../../lib/interfaces/Course';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import { IPagination } from '../../../lib/interfaces/Pagination';
import { PossibleRefs } from '../../../lib/interfaces/RefTypes';
import { RefType } from '../../Form/formUtils';
import DragAndDropList from '../../Smart/DragAndDropList/DragAndDropList';
import RefSelector from '../RefSelector/RefSelector';

type CoursePickerProps = {
	field: FormField;
	formValues: any;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const CoursePicker: FC<CoursePickerProps> = ({
	field,
	formValues,
	handleNamedContentFieldChange,
}) => {
	const currentFormValue = formValues[field.name].value;
	const initedCourseIds = currentFormValue
		? (Object.values(currentFormValue) as number[])
		: [];
	const [openCourseSelector, setOpenCourseSelector] = useState(false);
	const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);
	const [isInitied, setIsInited] = useState<Boolean>(false);

	const handleAddCourse = (ref: PossibleRefs) => {
		if (!selectedCourses.find((course: Course) => course.id === ref.id)) {
			setSelectedCourses([...selectedCourses, ref as Course]);
		}
		setOpenCourseSelector(false);
	};

	const handleDeleteCourse = (id: number) => {
		setSelectedCourses(selectedCourses.filter((course) => course.id !== id));
	};

	const { data: courses, isLoading } = useQuery<IPagination<Course>, Error>(
		[`courses-for-occupations-ids-${initedCourseIds.join('-')}`],
		() =>
			getPaginatedCourses(
				{ ids: initedCourseIds },
				{
					page: 1,
					limit: 100,
				},
			),
		{
			enabled: !isInitied && !!initedCourseIds?.length,
		},
	);

	useEffect(() => {
		if (!isInitied) {
			if (initedCourseIds.length) {
				if (courses) {
					const orderedCourses: Course[] = [];
					Object.values(currentFormValue).forEach((id) => {
						const course = courses.items.find((item) => item.id === id);
						if (course) {
							orderedCourses.push(course);
						}
					});
					setIsInited(true);
					setSelectedCourses(orderedCourses);
				}
			} else {
				setIsInited(true);
			}
		}
	}, [initedCourseIds, isInitied, courses]);

	const handleOnOrderChange = (newOrder: OrderObject) => {
		handleNamedContentFieldChange(
			{
				value: newOrder,
				options: formValues[field.name]?.options,
			},
			field.name,
		);
	};

	useEffect(() => {
		if (isInitied) {
			const newOrder: {
				[key: number]: number;
			} = {};
			selectedCourses.forEach((course, index) => {
				newOrder[index + 1] = course.id;
			});
			handleOnOrderChange(newOrder);
		}
	}, [selectedCourses, isInitied]);

	return (
		<div>
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<div>
					<div>
						<span>Course Picker</span>
						<Button
							onClick={() => {
								setOpenCourseSelector(true);
							}}
						>
							Add course
						</Button>
					</div>
					<div>
						<DragAndDropList
							items={selectedCourses}
							onOrderChange={handleOnOrderChange}
							onRemove={handleDeleteCourse}
							idFieldName="id"
						/>
					</div>
				</div>
			)}
			{openCourseSelector && (
				<RefSelector
					refType={RefType.COURSE_REF}
					onSelect={handleAddCourse}
					onCancel={() => {
						setOpenCourseSelector(false);
					}}
					disableItemIds={selectedCourses.map((course) => course.id)}
				/>
			)}
		</div>
	);
};

export default CoursePicker;
