/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */

import { FormPasswordValidation } from '../../lib/interfaces/Form';

export const ERRORS = {
	REQUIRED: 'requiredField',
	INVALID: 'isInvalid',
	MIN_LENGTH: 'minLengthValidationError',
	MAX_LENGTH: 'maxLengthValidationError',
	HAS_UPPERCASE: 'hasUpperCaseLetterValidationError',
	HAS_LOWERCASE: 'hasLowerCaseLetterValidationError',
	HAS_SPECIAL_CHAR: 'hasSpecialCharValidationError',
	HAS_NUMBER: 'hasNumberValidationError',
	ONLY_LATIN: 'hasLatinValidationError',
	PASSWORD_INVALID: 'passwordValidationGeneral',
	PASSWORD_DOESNT_MATCH: 'passwordDoesntMatch',
};

export const vEmail = (emailValue: string) =>
	emailValue.match(/^[\w-\.()+-]+@([\w-]+\.)+[\w-]{2,4}$/g);

export const vMaxLength = (value: string, max: number) =>
	value?.split('').length <= max;

export const vMinLength = (value: string, min: number) =>
	value?.split('').length >= min;

export const vHasSpecialCharacters = (value: string) =>
	/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);

const hasUpperCase = (value: string) => /[A-Z]/.test(value);
const hasLowerCase = (value: string) => /[a-z]/.test(value);
const hasNumbers = (value: string) => /\d/.test(value);
const hasNonalphas = (value: string) => /\W/.test(value);
// eslint-disable-next-line
const hasNonLatin = (value: string) => /[^\u0000-\u007f]/.test(value);

export const vPassword = (
	value: string,
	validation: FormPasswordValidation | undefined,
) => {
	const response = [];
	if (validation?.hasLowerCase && !hasLowerCase(value)) {
		response.push(ERRORS.HAS_LOWERCASE);
	}
	if (validation?.hasUpperCase && !hasUpperCase(value)) {
		response.push(ERRORS.HAS_UPPERCASE);
	}
	if (validation?.hasNonalphas && !hasNonalphas(value)) {
		response.push(ERRORS.HAS_SPECIAL_CHAR);
	}
	if (validation?.hasNumbers && !hasNumbers(value)) {
		response.push(ERRORS.HAS_NUMBER);
	}
	if (!validation?.allowNonLatin && hasNonLatin(value)) {
		response.push(ERRORS.ONLY_LATIN);
	}
	return response;
};

export const vShouldMatch = (value: any, matchValue: any) =>
	value === matchValue;
