import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import classNames from 'classnames';
import { defaultLaguage, supportedLaguages } from './formUtils';
import {
	FormField,
	IEntityContentFormValuesField,
	IFormFields,
} from '../../lib/interfaces/Form';
import ProgressButton from '../Simple/ProgressButton/ProgressButton';
import CreateAdditionalField from './AdditionalFields/CreateAdditionalField';
import FormFieldRenderer from './FormFieldsRenderer';
import useFormValidation from './useFormValidation';
import { flattenFormFieldsWithContent } from './form.helper';

import './Form.scss';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

type ContentFormProps = {
	formFields: IFormFields;
	initialValues?: any;
	saveButtonName: string;
	cancelButtonName?: string;
	isLoading?: boolean;
	saveButtonStyle?: string;
	fullWidth?: boolean;
	onSubmit: (formValues: any) => void;
	onCancel?: () => void;
	onAddField?: (field: FormField) => void;
	handleCustomFieldAction?: (data: any) => void;
	contentEntity: CONTENT_ENTITIES;
};

export type ResetFormHandler = {
	reset: () => void;
};

const ContentForm = forwardRef<ResetFormHandler, ContentFormProps>(
	(props, ref) => {
		const {
			initialValues,
			saveButtonName,
			cancelButtonName,
			isLoading,
			onSubmit,
			onCancel,
			onAddField,
			saveButtonStyle,
			fullWidth = false,
			formFields,
			handleCustomFieldAction,
			contentEntity,
		} = props;
		const { t } = useTranslation();
		const [formValues, setFormValues] = useState<any>(null);
		const [validation, setValidation] = useState<any>({});
		const [currentContentTab, setCurrentContentTab] = useState<string>(
			supportedLaguages[0],
		);
		const [isAdditionalFieldModalOpen, setIsAdditionalFieldModalOpen] =
			useState<boolean>(false);
		const [collapseAll, setCollapseAll] = useState<boolean>(false);

		const checkAndSetInitialFormValues = (initialFormValues: any) => {
			const flattenedFields = flattenFormFieldsWithContent(formFields);
			const initialDataMetaFields = Object.keys(initialFormValues).filter(
				(field) => !!formFields.meta.find((f) => f.name === field),
			);
			if (!initialDataMetaFields.length) {
				flattenedFields.forEach((item) => {
					// eslint-disable-next-line no-param-reassign
					initialFormValues[item.name] = { value: item.init };
				});
			}
			setFormValues(initialFormValues);
		};

		useEffect(() => {
			const initialFormValues: any = formValues || initialValues || {};
			checkAndSetInitialFormValues(initialFormValues);
		}, [formFields]);

		useImperativeHandle(ref, () => ({
			// Use case for clear form after submit. Control it from parent component via Ref
			reset() {
				checkAndSetInitialFormValues({});
			},
		}));

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setFormValues({
				...formValues,
				[e.target.name]: { value: e.target.value },
			});
		};

		const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setFormValues({
				...formValues,
				[e.target.name]: { value: e.target.checked },
			});
		};

		const handleSimpleUpdate = (value: any, fieldName: string) => {
			setFormValues({
				...formValues,
				[fieldName]: { value },
			});
		};

		const handleTagsChange = (
			value: string | number | undefined | (string | number)[],
			name: string,
		) => {
			setFormValues({
				...formValues,
				[name]: { value },
			});
		};

		const handleFileUploaded = (value: string | undefined, name: string) => {
			setFormValues({
				...formValues,
				[name]: { value },
			});
		};

		const handleRihEditorChange = (data: string, name: string) => {
			setFormValues({
				...formValues,
				[name]: { value: data },
			});
		};

		const handleNamedContentFieldChange = (
			data: IEntityContentFormValuesField,
			name: string,
		) => {
			setFormValues({
				...formValues,
				[name]: data,
			});
		};

		const handleSaveAdditionalField = (additionalField: FormField) => {
			if (onAddField) {
				setIsAdditionalFieldModalOpen(false);
				onAddField(additionalField);
			}
		};

		const { validate } = useFormValidation();

		const submit = () => {
			const flattenedFields = flattenFormFieldsWithContent(formFields);
			const invalidFields = validate(flattenedFields, formValues, true);
			setValidation(invalidFields);
			if (Object.keys(invalidFields).length) return;
			onSubmit(formValues);
		};

		const formClass = classNames({
			'Form__full-width': fullWidth,
		});

		if (!formValues) return <div>Loading...</div>;

		const generalFieldProps = {
			formValues,
			validation,
			handleChange,
			handleTagsChange,
			handleFileUploaded,
			handleRihEditorChange,
			handleCheckboxChange,
			handleNamedContentFieldChange,
			handleSimpleUpdate,
			handleCustomFieldAction,
		};

		return (
			<div className={formClass}>
				<form noValidate className="Form">
					{formFields &&
						formFields.meta.map((field, index) => (
							<FormFieldRenderer
								key={field.name}
								language={defaultLaguage}
								field={field}
								fieldIndex={index}
								fieldsLength={formFields.meta.length}
								collapseAll={collapseAll}
								{...generalFieldProps}
							/>
						))}

					{formFields && (
						<div>
							<div style={{ display: 'flex' }}>
								<ToggleButtonGroup
									color="primary"
									value={currentContentTab}
									exclusive
									onChange={(
										event: React.MouseEvent<HTMLElement>,
										newAlignment: string,
									) => {
										setCurrentContentTab(newAlignment);
									}}
									aria-label="Platform"
								>
									{Object.keys(formFields.content).map((lang) => (
										<ToggleButton key={`content_tab_button_${lang}`} value={lang}>
											{t(`lang_${lang}`)}
										</ToggleButton>
									))}
								</ToggleButtonGroup>
							</div>
							{Object.keys(formFields.content).map((lang) => (
								<div
									key={`content_tab_${lang}`}
									style={{
										display: currentContentTab === lang ? 'block' : 'none',
										borderLeft: '1px solid #ccc',
									}}
								>
									<Typography variant="h6" component="h5" style={{ padding: '20px' }}>
										{t(`lang_${lang}`)} content:{' '}
									</Typography>
									{formFields.content[lang].map((field, index) => (
										<FormFieldRenderer
											key={field.name}
											language={lang}
											field={field}
											fieldIndex={index}
											fieldsLength={formFields.content[lang].length}
											collapseAll={collapseAll}
											{...generalFieldProps}
										/>
									))}
								</div>
							))}
						</div>
					)}

					{isAdditionalFieldModalOpen && (
						<CreateAdditionalField
							existingFields={flattenFormFieldsWithContent(formFields)}
							onSave={handleSaveAdditionalField}
							onCancel={() => setIsAdditionalFieldModalOpen(false)}
							contentEntity={contentEntity}
						/>
					)}
					<Button
						disableElevation
						color="inherit"
						onClick={() => setIsAdditionalFieldModalOpen(true)}
						sx={{ marginLeft: '10px' }}
					>
						+ Add Field
					</Button>

					<hr />

					<ProgressButton
						disableElevation
						handleClick={submit}
						isLoading={isLoading}
						width={saveButtonStyle}
						text={saveButtonName}
						// TODO: When is visible -> hide fixed one from top
					/>
					<div
						style={{
							position: 'fixed',
							top: 10,
							right: 10,
							zIndex: 4,
						}}
					>
						<ProgressButton
							disableElevation
							handleClick={submit}
							isLoading={isLoading}
							width={saveButtonStyle}
							text={saveButtonName}
							// style={{ height: '57px', width: '100%', borderRadius: 0 }}
						/>
						{cancelButtonName && onCancel && (
							<Button
								disableElevation
								variant="contained"
								color="inherit"
								onClick={onCancel}
								sx={{ marginLeft: '10px', width: '100%', height: '56px' }}
							>
								{cancelButtonName}
							</Button>
						)}
					</div>
					<div className="collapse-all-button">
						<Button onClick={() => setCollapseAll(!collapseAll)}>
							{!collapseAll ? 'Collapse' : 'Uncollapse'} all
						</Button>
					</div>
				</form>
			</div>
		);
	},
);

ContentForm.defaultProps = {
	initialValues: {},
};

export default ContentForm;
