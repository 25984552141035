import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
	getCourseOccupationById,
	updateCourseOccupation,
} from '../../api/apiRequests/courseOccupation';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { ICourseOccupation } from '../../lib/interfaces/CourseOccupation';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { courseOccupationFields } from './CourseOccupationFormFields';

const CourseOccupationEdit = () => {
	const { id = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();

	const singleCourseOccupation = useQuery<ICourseOccupation, Error>(
		[`CourseOccupation-${id}`],
		() => getCourseOccupationById(Number(id)),
	);
	const { data, isLoading, error, refetch } = singleCourseOccupation;

	const updateCourseOccupationMutation = useMutation<
		ICourseOccupation,
		Error,
		ICourseOccupation
	>((formValues: ICourseOccupation) =>
		updateCourseOccupation(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateCourseOccupationMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Course Occupation has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateCourseOccupationMutation.mutate(formValues);
		}
	};

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={courseOccupationFields}
			entityName="Course Occupation"
			contentEntity={CONTENT_ENTITIES.COURSE_OCCUPATION}
		/>
	) : null;
};

export default CourseOccupationEdit;
