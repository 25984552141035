import React, { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { SUBMIT_BTN_TYPE } from '../../Form/formUtils';

type ProgressButtonProps = {
	handleClick: () => void;
	text: string;
	disableElevation?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	width?: string;
	style?: any;
	color?:
		| 'primary'
		| 'inherit'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| undefined;
};

const ProgressButton: FC<ProgressButtonProps> = ({
	disableElevation = true,
	handleClick,
	isLoading,
	text,
	width,
	disabled,
	color = 'primary',
	style,
}) => {
	const styles = style ? { ...style } : {};
	return (
		<Button
			variant="contained"
			disableElevation={disableElevation}
			onClick={handleClick}
			disabled={disabled || isLoading}
			style={{
				width: width === SUBMIT_BTN_TYPE.FULL ? '100%' : 'auto',
				...styles,
			}}
			size={width === SUBMIT_BTN_TYPE.FULL ? 'large' : 'medium'}
			color={color}
		>
			{isLoading && (
				<CircularProgress
					size={15}
					color="secondary"
					sx={{ marginRight: '10px' }}
				/>
			)}
			{text}
		</Button>
	);
};
export default ProgressButton;
