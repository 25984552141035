import React, { FC, useState } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export type OptionEntity = {
	label: string;
	id: string | number;
};

type TagsProps = {
	label: string;
	required: boolean;
	defaultValue?: string[] | number[] | undefined;
	name: string;
	freeSolo?: boolean;
	className: string;
	selectedOptions?: OptionEntity[];
	defaultOptions?: OptionEntity[];
	onItemsChange: (value: (string | number)[] | undefined) => void;
	readOnly?: boolean;
};

const Tags: FC<TagsProps> = (props) => {
	const {
		label,
		required,
		name,
		freeSolo,
		selectedOptions: preSelectedOptions,
		defaultOptions: predefinedOptions,
		onItemsChange,
		readOnly,
	} = props;
	const getSelectedOptionsForFreeSolo = (
		options: string[] | OptionEntity[] | undefined,
	) => {
		if (!options) return [];
		const converted = options.map((value: string | OptionEntity) => {
			if (typeof value === 'string') {
				return {
					label: value,
					id: value,
				};
			}
			return value;
		});
		return converted;
	};
	const [selectedOptions, setSelectedOptions] = useState<OptionEntity[]>(
		preSelectedOptions || [],
	);
	const [optionsList] = useState<OptionEntity[]>(predefinedOptions || []);

	const onchange = (newValue: OptionEntity[]) => {
		if (!freeSolo) {
			const change = newValue.map((option) => option.id);
			onItemsChange(change);
		} else {
			const newOptions = getSelectedOptionsForFreeSolo(newValue);
			setSelectedOptions([...newOptions]);
			onItemsChange(newOptions.map((option) => option.id));
		}
	};
	const renderTags = (value: OptionEntity[], getTagProps: any) =>
		value.map((option, index) => (
			<Chip
				variant="outlined"
				label={option.label || option}
				{...getTagProps({ index })}
			/>
		));

	return (
		<Autocomplete
			multiple
			id={`tags-${name}`}
			options={optionsList}
			defaultValue={selectedOptions}
			onChange={(e, newValue: any) => onchange(newValue)}
			freeSolo={freeSolo}
			renderTags={(value, getTagProps) => renderTags(value, getTagProps)}
			readOnly={readOnly}
			renderInput={(params) => (
				<TextField
					{...params}
					name={name}
					required={required}
					label={label}
					placeholder={label}
				/>
			)}
		/>
	);
};

Tags.defaultProps = {
	defaultValue: [],
	freeSolo: false,
};

export default Tags;
