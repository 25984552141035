import sanitizeHtml from 'sanitize-html';

const htmlQuizOptionSanitizer = (html: string) =>
	sanitizeHtml(html, {
		allowedTags: ['div', 'p', 'em', 'strong', 'code', 'pre'],
		allowedAttributes: {
			code: ['class'],
		},
	});

export default htmlQuizOptionSanitizer;
