import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../../lib/interfaces/User';

type InitialState = {
	currentUser: User | null;
	isRegisterModalOpen: Boolean;
};

const initialState = {
	currentUser: null,
	isRegisterModalOpen: false,
} as InitialState;

const userReducer = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
	},
});

export const { setCurrentUser } = userReducer.actions;
export default userReducer;
