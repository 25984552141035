import { FormField } from '../../lib/interfaces/Form';
import {
	FIELD_TYPES,
	createInitialContent,
	INPUT_TYPES,
} from '../Form/formUtils';

export const courseOccupationFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'isPublished',
		init: false,
		label: 'Is Published',
		required: false,
		type: FIELD_TYPES.CHECKBOX,
	},
	{
		name: 'sortNumber',
		init: [],
		label: 'Order Number',
		required: true,
		freeSolo: false,
		type: FIELD_TYPES.TEXT,
		inputType: INPUT_TYPES.NUMBER,
	},
	{
		name: 'courseOrder',
		init: {},
		label: 'Courses',
		required: true,
		type: FIELD_TYPES.COURSE_PICKER,
	},
];

export const createCourseOccupationInitialContent = () =>
	createInitialContent();
