import React, { FC, useState } from 'react';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import { FIELD_TYPES, INPUT_TYPES } from '../../Form/formUtils';
import InputTextField from '../../Form/InputTextField';
import CKEditorField from '../CKEditor';

type ContentBlockFieldProps = {
	field: FormField;
	formValues: any;
	validation: any;
	handleContentBlockChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const ContentBlockField: FC<ContentBlockFieldProps> = ({
	field,
	formValues,
	validation,
	handleContentBlockChange,
}) => {
	const [content, setContent] = useState<string>(
		formValues[field.name]?.value || '',
	);
	const [label, setLabel] = useState<string>(
		formValues[field.name]?.options?.label || '',
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLabel(e.target.value);
		handleContentBlockChange(
			{ value: content, options: { label: e.target.value } },
			field.name,
		);
	};

	const handleContentChange = (data: string) => {
		setContent(data);
		handleContentBlockChange({ value: data, options: { label } }, field.name);
	};

	const blockTitle = () => {
		switch (field.type) {
			case FIELD_TYPES.RICH_EDITOR:
				return 'Content block';
			case FIELD_TYPES.OVERVIEW_BLOCK:
				return 'Overview block';
			case FIELD_TYPES.CONCLUSION_BLOCK:
				return 'Summary block';
			case FIELD_TYPES.HINT:
				return 'Hint block';
			default:
				return 'Content block';
		}
	};

	return (
		<div>
			{field.custom && <div style={{ marginBottom: '15px' }}>{blockTitle()}</div>}
			{field.custom && (
				<InputTextField
					onChange={handleChange}
					variant="outlined"
					fullWidth
					label="Title"
					name={field.name}
					type={field.inputType || INPUT_TYPES.TEXT}
					defaultValue={label}
					className={validation[field.name] ? 'invalid' : ''}
					fieldInputType={field.inputType}
				/>
			)}
			<CKEditorField
				{...field}
				label="Description"
				defaultValue={content}
				onChange={(data: string) => {
					handleContentChange(data);
				}}
			/>
		</div>
	);
};

export default ContentBlockField;
