import { FormField } from '../../lib/interfaces/Form';
import { FIELD_TYPES, createInitialContent } from '../Form/formUtils';

export const videoItemFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'tags',
		init: [],
		label: 'Tags',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: true,
	},
];

const contentExtraFields = [
	{
		name: 'posterurl',
		content: '',
		label: 'Poster URL',
		type: FIELD_TYPES.FILE,
	},
	{
		name: 'videourl',
		content: '',
		label: 'Video URL',
		type: FIELD_TYPES.VIDEO,
	},
];

export const createVideoInitialContent = () =>
	createInitialContent(contentExtraFields);
