import React, { FC, useState } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import {
	QuizOption,
	QuizQuestion,
	QuizQuestionTypes,
} from '../../../lib/interfaces/Quiz';
import { INPUT_TYPES } from '../../Form/formUtils';
import InputTextField from '../../Form/InputTextField';
import CKEditorField from '../CKEditor';
import QuizQuestionOptions from './QuizQuestionOptions';

const QuizEditorOptions = {
	OPTIONS: {
		label: 'Options',
		value: QuizQuestionTypes.OPTIONS,
	},
	MULTIPLE_OPTIONS: {
		label: 'Multiple Options',
		value: QuizQuestionTypes.MULTIPLE_OPTIONS,
	},
	EXACT_ANSWER: {
		label: 'Exact Answer',
		value: QuizQuestionTypes.EXACT_ANSWER,
	},
};

type QuizQuestionEditorProps = {
	question: QuizQuestion;
	updateQuestion: (question: QuizQuestion) => void;
};

const QuizQuestionEditor: FC<QuizQuestionEditorProps> = ({
	question,
	updateQuestion,
}) => {
	const [questionType, setQuestionType] = useState<number>(
		question?.type || QuizQuestionTypes.OPTIONS,
	);
	const [questionText, setQuestionText] = useState<string>(question?.text || '');

	const handleChange = ({
		text,
		type,
		answer,
		options,
	}: {
		text?: string;
		type?: number;
		answer?: string[];
		options?: QuizOption[];
	}) => {
		updateQuestion({
			id: question.id,
			text: text || questionText,
			answer: answer || question.answer || [],
			options: options || question.options || [],
			type: type || questionType,
		});
	};

	const handleTypeChange = (event: SelectChangeEvent) => {
		setQuestionType(Number(event.target.value));
		handleChange({ type: Number(event.target.value) });
	};

	const handleQuestionTextChange = (data: string) => {
		setQuestionText(data);
		handleChange({ text: data });
	};

	const updateOptions = ({
		options,
		answer,
	}: {
		options: QuizOption[];
		answer: string[];
	}) => {
		handleChange({
			options,
			answer,
		});
	};

	return (
		<div>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Type</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={questionType.toString()}
					label="Type"
					onChange={handleTypeChange}
				>
					{Object.values(QuizEditorOptions).map((option) => (
						<MenuItem key={option.value} value={option.value.toString()}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<div style={{ marginTop: '15px' }}>
				<CKEditorField
					label="Question Text"
					defaultValue={questionText}
					onChange={(data: string) => {
						handleQuestionTextChange(data);
					}}
				/>
			</div>
			{Number(questionType) !== QuizQuestionTypes.EXACT_ANSWER && (
				<div>
					<QuizQuestionOptions question={question} updateOptions={updateOptions} />
				</div>
			)}
			{Number(questionType) === QuizQuestionTypes.EXACT_ANSWER && (
				<div style={{ marginTop: '15px' }}>
					<InputTextField
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							updateOptions({
								options: [],
								answer: [e.target.value],
							})
						}
						variant="outlined"
						required
						fullWidth
						label="Answer"
						name="answer"
						type={INPUT_TYPES.TEXT}
						defaultValue={question.answer?.[0] || ''}
						// fieldInputType={field.inputType}
					/>
				</div>
			)}
		</div>
	);
};

export default QuizQuestionEditor;
