import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { QuizDTO } from '../../lib/interfaces/Quiz';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getQuizBySlug: (slug: string) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/quiz/${slug}`,
		method: API_METHODS.GET,
	}),

	getAllQuizzes: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/quiz`,
		method: API_METHODS.GET,
	}),

	getPaginatedQuizes: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(`${BASE_URL}/quiz`, pagination, filter);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createQuiz: (data: QuizDTO) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/quiz`,
		headers: createHeader(null, true),
		data,
	}),

	updateQuiz: (data: Partial<QuizDTO>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/quiz/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteQuiz: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/quiz/${id}`,
		headers: createHeader(null, true),
	}),
};

// QUIZ

export const getQuizBySlug = async (slug: string) =>
	axiosRequest(endpoints.getQuizBySlug(slug));

export const getAllQuizzes = async () =>
	axiosRequest(endpoints.getAllQuizzes());

export const getPaginatedQuizes = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedQuizes(filter, pagination));

export const createQuiz = async (data: QuizDTO) =>
	axiosRequest(endpoints.createQuiz(data));

export const updateQuiz = async (
	data: Partial<QuizDTO>,
	id: number | undefined,
) => axiosRequest(endpoints.updateQuiz(data, id));

export const deleteQuiz = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteQuiz(id));
