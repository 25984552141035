import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { initialContent } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import {
	ISubscriptionPlan,
	ISubscriptionPlanDTO,
	ISubscriptionPlanRO,
} from '../../lib/interfaces/SubscriptionPlan';
import { createSubscriptionPlan } from '../../api/apiRequests/subscriptionPlans';
import { subscriptionPlanFields } from './SubscriptionPlansFormFields';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

const SubscriptionPlanCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createSubscriptionPlanMutation = useMutation<
		ISubscriptionPlanRO,
		Error,
		ISubscriptionPlanDTO
	>((formValues) => createSubscriptionPlan(formValues));
	const { isLoading, isSuccess, error } = createSubscriptionPlanMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['subscriptionPlans']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createSubscriptionPlanMutation.mutate(formValues);
	};

	const initialSubscriptionPlanContent = cloneDeep(initialContent);
	const subscriptionPlanInit: Partial<ISubscriptionPlan> = {
		content: initialSubscriptionPlanContent,
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={subscriptionPlanInit}
			error={error}
			saveButtonName="Create"
			metaFields={subscriptionPlanFields}
			entityName="Subscription Plan"
			contentEntity={CONTENT_ENTITIES.SUBSCRIPTION_PLAN}
		/>
	);
};

export default SubscriptionPlanCreate;
