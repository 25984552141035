import { FC, useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import MonacoCodeEditorField from '../CodeEditor/MonacoCodeEditor';

type RefSelectorFieldProps = {
	field: FormField;
	formValues: any;
	language: string;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const CodeBlock: FC<RefSelectorFieldProps> = ({
	field,
	formValues,
	language,
	handleNamedContentFieldChange,
}) => {
	const [selectedValue, setSelectedValue] = useState<string>(
		formValues[field.name]?.options?.language || 'jsx',
	);
	const [loading, setLoading] = useState<boolean>(false);
	console.log(field, formValues, language, handleNamedContentFieldChange);
	const onChange = (code: string) => {
		handleNamedContentFieldChange(
			{
				value: code,
				options: {
					language: selectedValue,
				},
			},
			field.name,
		);
	};

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 200);
	}, [selectedValue]);

	const selectOptions = [
		'jsx',
		'javascript',
		'html',
		'typescript',
		'tsx',
		'css',
		'scss',
	];
	const handleTypeChange = (event: SelectChangeEvent) => {
		setSelectedValue(event.target.value);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					language: event.target.value,
				},
			},
			field.name,
		);
	};
	const getEditorLanguage = () => {
		if (selectedValue === 'jsx') {
			return 'javascript';
		}
		return selectedValue;
	};
	const editorLanguage = getEditorLanguage();
	return (
		<div>
			<div style={{ padding: '5px 0' }}>Code block</div>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedValue}
				label={field.label}
				onChange={handleTypeChange}
			>
				{!!selectOptions &&
					selectOptions.map((option: string) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
			</Select>
			{loading ? (
				<div>Loading...</div>
			) : (
				<MonacoCodeEditorField
					langauge={editorLanguage}
					defaultValue={formValues[field.name]?.value}
					onChange={onChange}
					height="150px"
				/>
			)}
		</div>
	);
};

export default CodeBlock;
