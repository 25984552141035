import { ICodeTask, ICodeTaskDTO } from '../../lib/interfaces/CodeTask';
import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getCodeTaskBySlug: (slug: string) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/code-task/${slug}`,
		method: API_METHODS.GET,
	}),

	getAllCodeTasks: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/code-task`,
		method: API_METHODS.GET,
	}),

	getPaginatedCodeTasks: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/code-task`,
			pagination,
			filter,
		);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createCodeTask: (data: ICodeTaskDTO) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/code-task`,
		headers: createHeader(null, true),
		data,
	}),

	updateCodeTask: (data: Partial<ICodeTaskDTO>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/code-task/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteCodeTask: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/code-task/${id}`,
		headers: createHeader(null, true),
	}),
};

// CODE TASKS

export const getCodeTaskBySlug = async (slug: string): Promise<ICodeTask> =>
	axiosRequest(endpoints.getCodeTaskBySlug(slug));

export const getAllCodeTasks = async () =>
	axiosRequest(endpoints.getAllCodeTasks());

export const createCodeTask = async (data: ICodeTaskDTO) =>
	axiosRequest(endpoints.createCodeTask(data));

export const updateCodeTask = async (
	data: Partial<ICodeTaskDTO>,
	id: number | undefined,
) => axiosRequest(endpoints.updateCodeTask(data, id));

export const getPaginatedCodeTasks = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedCodeTasks(filter, pagination));

export const deleteCodeTask = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteCodeTask(id));
