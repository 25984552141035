import axios, { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { BASE_URL } from './endpointBuilder';

export const AxiosInstance = axios.create();

AxiosInstance.interceptors.request.use(async (req: AxiosRequestConfig) => {
	const needAuthorization = req?.headers?.Authorization;

	if (!needAuthorization) return req;

	if (typeof req?.headers?.Authorization === 'string') {
		const authToken = req?.headers?.Authorization.split(' ')[1];

		const user: { exp: number } = jwt_decode(authToken);
		const expiration = user.exp * 1000;
		const currentTimeStamp = +new Date();

		const isTokenExpired = expiration - currentTimeStamp <= 0;

		if (isTokenExpired) {
			// logout();
			return false;
		}

		const isTokenCloseToExpiration =
			expiration - currentTimeStamp < 60 * 60 * 24 * 1000;

		if (!isTokenCloseToExpiration) return req;

		try {
			const response = await axios.get(`${BASE_URL}/auth/refreshToken`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});

			const newToken = response?.data?.token;
			const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
			localStorage.setItem('token', JSON.stringify(newToken));
			localStorage.setItem(
				'currentUser',
				JSON.stringify({
					...currentUser,
					token: newToken,
				}),
			);

			// eslint-disable-next-line no-param-reassign
			req.headers.Authorization = `Bearer ${newToken}`;
			return req;
		} catch (error: any) {
			console.error('Refresh token failure: ', error?.response?.data?.message);
			return error;
		}
	} else {
		return req;
	}
});

export const axiosRequest = async (requestOptions: AxiosRequestConfig) => {
	const response = await AxiosInstance(requestOptions);
	return response.data;
};
