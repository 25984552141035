import React, { FC, useState } from 'react';
import { uploadFile, uploadVideo } from '../../api/apis';
import { FILE_TYPE } from '../../lib/helpers/consts';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../lib/interfaces/Form';
import { INPUT_TYPES } from '../Form/formUtils';
import InputTextField from '../Form/InputTextField';
import './FileUpload.scss';

type FileUploadProps = {
	field: FormField;
	formValues: any;
	fileType: FILE_TYPE;
	required: boolean;
	handleFileChange: (data: IEntityContentFormValuesField, name: string) => void;
};

const FileUpload: FC<FileUploadProps> = ({
	field,
	handleFileChange,
	required,
	fileType,
	formValues,
}) => {
	const [image, setImage] = useState<string>(
		formValues[field.name]?.value || '',
	);
	const [video, setVideo] = useState<string>(
		formValues[field.name]?.value || '',
	);
	const [fileMaxWidth, setFileMaxWidth] = useState<string>(
		formValues[field.name]?.options?.maxWidth || '',
	);
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event?.target?.files?.[0];
		if (file) {
			if (fileType === FILE_TYPE.IMAGE) {
				const body = new FormData();
				body.append('image', file);
				uploadFile(body).then(
					(res) => {
						setImage(res.url);
						handleFileChange(
							{
								value: res.url,
								options: formValues[field.name]?.options,
							},
							field.name,
						);
					},
					(err) => {
						console.error(err);
					},
				);
			} else {
				const body = new FormData();
				body.append('video', file);
				uploadVideo(body).then(
					(res) => {
						setVideo(res.url);
						handleFileChange(
							{
								value: res.url,
								options: formValues[field.name]?.options,
							},
							field.name,
						);
					},
					(err) => {
						console.error(err);
					},
				);
			}
		}
	};

	const handleFileMaxWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFileMaxWidth(e.target.value);
		const options = formValues[field.name]?.options || {};
		handleFileChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...options,
					maxWidth: e.target.value,
				},
			},
			field.name,
		);
	};

	const acceptTypes =
		fileType === FILE_TYPE.IMAGE
			? 'image/png, image/jpeg, image/svg+xml'
			: 'video/mp4';
	return (
		<div className="FileUpload">
			<div>
				<div style={{ marginBottom: '10px' }}>{field.label}</div>
				{fileType === FILE_TYPE.IMAGE && image && (
					<img
						className="FileUpload__thumbnail"
						src={image}
						style={{ maxWidth: '500px' }}
						alt="file"
					/>
				)}
				{fileType === FILE_TYPE.VIDEO && video && (
					<video style={{ maxWidth: '500px' }} controls>
						<source src={video} type="video/mp4" />
					</video>
				)}
				<div>
					<label htmlFor="image">
						<input
							required={required}
							type="file"
							id="image"
							name={fileType}
							accept={acceptTypes}
							onChange={onChange}
						/>
					</label>
				</div>
			</div>
			<div>
				<InputTextField
					onChange={handleFileMaxWidthChange}
					variant="outlined"
					fullWidth
					label="Max width (px)"
					name={field.name}
					type={INPUT_TYPES.NUMBER}
					defaultValue={fileMaxWidth}
					sx={{ marginTop: '15px', marginLeft: '20px' }}
				/>
			</div>
		</div>
	);
};

export default FileUpload;
