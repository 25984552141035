/* eslint-disable no-shadow */
export const SUBSCRIPTION_PLANS = {
	FREE: 1,
	STANDARD: 2,
	PRO: 3,
};

export enum PRODUCTS {
	LIVE_CODING = 'live_coding',
	MOCK_INTERVIEW = 'mock_interview',
	MENTORING = 'mentoring',
	SUBSCRIPTION_PLAN = 'subscription_plan',
}

export enum PRODUCT_FORM_TYPES {
	INTERVIEW = 'interview',
	CONSULTATION = 'consultation',
	EXERCISE_REVIEW = 'exercise',
	SUBSCRIPTION_PLANS = 'subscription_plan',
}

export enum ProductStatus {
	PENDING = 'pending',
	PROCESSING = 'processing',
	PAID = 'paid',
	PAYMENT_ERROR = 'payment_error',
	CONFIRMED = 'confirmed',
	DELIVERED = 'delivered',
	REFUNDED_CANCELED = 'refunded_canceled',
	REDUNDED_DELIVERED = 'refunded_delivered',
}

export enum FILE_TYPE {
	IMAGE = 'image',
	VIDEO = 'video',
}

export enum CONTENT_ENTITIES {
	COURSE = 'course',
	TASK = 'task',
	CODE_TASK = 'codeTask',
	VIDEO_ITEM = 'videoItem',
	QUIZ = 'quiz',
	SUBSCRIPTION_PLAN = 'subscriptionPlan',
	PRODUCT = 'product',
	COURSE_OCCUPATION = 'courseOccupation',
	LEGAL = 'legal',
}

export type OrderObject = {
	[key: number]: number;
};
