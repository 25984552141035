import { IEntityContent } from './Form';

export enum LegalType {
	PRIVACY_POLICY = 'privacy_policy',
	COOKIES_MESSAGE = 'cookies_message',
	COOKIES_POLICY = 'cookies_policy',
	TERMS_AND_CONDITIONS = 'terms_and_conditions',
}

export interface ILegal {
	id: number;
	name: string;
	slug: string;
	identity: LegalType;
	version: number;
	content: IEntityContent;
	changeLog: string;
	isPublished: boolean;
}

export interface ILegalDTO {
	name: string;
	identity: LegalType;
	content: IEntityContent;
	isPublished?: boolean;
	isUpdateMajor: boolean;
}
